import { useMemo, useRef } from 'react';
import * as random from 'maath/random/dist/maath-random.esm';
import { useFrame } from '@react-three/fiber';
import { PointMaterial, Points } from '@react-three/drei';

export default function Stars({ noStars = 1000, radius = 1, color = '#ffffff', size = 0.01, ...props }) {
    const ref = useRef();

    // Memoize sphere positions to avoid recalculating on every render
    const sphere = useMemo(() => {
        return random.inSphere(new Float32Array(noStars * 3), { radius });
    }, [noStars, radius]);

    // Update group position based on mouse movement
    useFrame((state, delta) => {
        // const { width, height } = state.viewport;
        // const x = (state.mouse.x * width) / 2;
        // const y = (state.mouse.y * height) / 2;
        
        // ref.current.position.x = x * 0.05;
        // ref.current.position.y = -y * 0.05;
        ref.current.rotation.x -= delta / 50;
    });

    return (
        <group
            ref={ref}
            position={[0, 0, 0]}
            rotation={[0, 0, Math.PI / 4]}
            scale={1}
        >
            <Points positions={sphere} frustumCulled stride={3} {...props}>
                <PointMaterial 
                    transparent 
                    color={color} 
                    size={size} 
                    sizeAttenuation 
                    depthWrite={false} 
                />
            </Points>
        </group>
    );
}
