import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { MeshBasicMaterial, MeshDepthMaterial, MeshLambertMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry, TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useSpring, animated } from '@react-spring/three';

export default function Sun(props) {
    const material = new MeshBasicMaterial({ map: props.textures.sun, transparent: true, depthWrite: false });

    const { rotationZ } = useSpring({
        from: { rotationZ: 0 },
        to: { rotationZ: 2 * Math.PI },
        config: { duration: 400000, easing: t => t },
        loop: true,
    });

    return (
        <animated.mesh
            geometry={props.plane}
            rotation-z={rotationZ}
            position={props.position} material={material} scale={props.scale} />
    );
}