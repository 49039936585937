import React, { useEffect, useRef } from 'react';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere, useGLTF, useTexture, useVideoTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import gsap from 'gsap';
import { useSpring, animated } from '@react-spring/three';

export default function Earth(props) {

    const materialEarth = new MeshStandardMaterial({ map: props.textures.textureEarthCanare, toneMapped: false });
    const materialCloudsEarth = new MeshBasicMaterial({ map: props.textures.cloudsEarth, transparent: true });

    const { rotationY: rotationYEarth } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 90000, easing: t => t },
        loop: true,
    });

    const { rotationY: rotationYClouds } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 120000, easing: t => t },
        loop: true,
    });


    const moveCamera = () => {
        document.title = "ProjectX • Earth";
        props.setInFocus("earth");
        //props.setAxisOpacity(0);
        if (props.windowWidth > 1060)
            props.controlsRef.current?.setLookAt(-0.7, -0.5, 7, -0.7, -0.5, 1, true);
    }

    const pin = useRef();
    useFrame((state, delta) => {
        const time = state.clock.getElapsedTime();

        const minScale = props.windowWidth > 1060 ? 0.002 : 0.01;
        const maxScale = props.windowWidth > 1060 ? 0.005 : 0.04;

        const oscillation = Math.sin(time);

        const scale = minScale + (maxScale - minScale) * (oscillation + 1) / 2;

        pin.current.scale.set(scale, scale, scale);
    });

    const { nodes: nodesExclamation, materials: materialsExclamation } = useGLTF(`${process.env.PUBLIC_URL}/v2/saturn/webp/exclamation.glb`)
    const meshExclamation = useRef();
    return (
        <>
            <group>

                {
                    props.animationAlertEarth ? (
                        <mesh
                            onClick={() => {
                                window.open("https://twitter.com/ProjectX_DAO")
                            }}
                            ref={meshExclamation}
                            position={props.windowWidth > 1060 ? [props.position[0], props.position[1] + 0.45, props.position[2]] : [props.position[0], props.position[1] + 1.7, props.position[2]]}
                            geometry={nodesExclamation.Text.geometry}
                            scale={props.windowWidth > 1060 ? 0.25 : 1}
                            rotation={[Math.PI / 2, 0, 0]}
                        >
                            <meshStandardMaterial color={"yellow"} metalness={0.1} roughness={0.9} />
                        </mesh>
                    ) : null
                }

                <animated.group
                    rotation-y={rotationYEarth}
                    position={props.position}>

                    <animated.mesh
                        onPointerEnter={() => { props.setIsActive("Earth"); }}
                        onPointerLeave={() => { props.setIsActive(null); }}
                        onClick={() => {
                            moveCamera()
                        }}
                        rotation-y={rotationYClouds}
                        position={[0, 0, 0]}
                        geometry={props.sphere}
                        material={materialCloudsEarth}
                        scale={props.scale * 1.01}
                    />

                    <animated.mesh
                        rotation={[0, 5, 0]}
                        position={[0, 0, 0]}
                        geometry={props.sphere}
                        material={materialEarth}
                        scale={props.scale}
                    />
                    <mesh ref={pin} position={props.windowWidth > 1060 ? [-0.04, 0.125, 0.27] : [-0.09, 0.4, 0.915]} rotation={props.windowWidth > 1060 ? [-0.4, 0, 0] : [-0.4, -0.1, 0]}>
                        <circleGeometry />
                        <meshStandardMaterial emissive={"green"} emissiveIntensity={10} />
                    </mesh>

                </animated.group>
            </group>
        </>
    );
}
