import React, { useRef, useState } from 'react';
import { MeshRefractionMaterial, useGLTF } from '@react-three/drei';
import { MeshStandardMaterial } from 'three';
import { useLoader } from '@react-three/fiber';
import { RGBELoader } from 'three-stdlib';
import { useSpring, animated } from '@react-spring/three';

export function Asteroid2(props) {
    const { nodes: nodes2 } = useGLTF(`${process.env.PUBLIC_URL}/v2/gold/webp/asteroid2.glb`);

    const ref = useRef();
    const { rotationY: rotationYGold } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 10000, easing: t => t },
        loop: true,
    });

    const { rotationX: rotationXGold } = useSpring({
        from: { rotationX: 0 },
        to: { rotationX: 2 * Math.PI },
        config: { duration: 7000, easing: t => t },
        loop: true,
    });


    const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr');

    const getMaterial = (index) => {
        const refractionCount = Math.max(0, Math.min(props.animationGold, 10));

        if (index < refractionCount) {

            return <MeshRefractionMaterial envMap={texture} toneMapped={false} />;
        } else {

            return <meshStandardMaterial transparent={true} color={"#ffd500"} metalness={0.9} roughness={0.5} />;
        }
    };


    const meshes = [
        { position: [0.2, 0, 0], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0024) },
        { position: [0.2, 0.1, 0.2], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0022) },
        { position: [-0.2, 0.1, -0.33], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0028) },
        { position: [-0.4, -0.1, -0.17], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0021) },
        { position: [-0.5, -0, -0.1], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0023), rotation: [0, 1, 1] },//(props.windowWidth && props.windowWidth < 1060 ? 0.0004 : 0.0031) },
        { position: [0.2, -0.2, 0.23], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0018) },
        { position: [0.13, -0.14, 0.4], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0016) },
        { position: [0.2, -0.3, -0.5], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0029) },
        { position: [-0.2, 0.2, 0.4], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0022) },
        { position: [0.3, -0.1, -0.3], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0019) },
        { position: [0.3, -0.2, 0.3], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0012) },
        { position: [-0.3, 0, 0.45], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0018) },
        { position: [0.1, 0, -0.6], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0014) },
        { position: [-0.2, -0.1, 1.2], scale: props.scale * (props.windowWidth && props.windowWidth < 1060 ? 0.00062 : 0.0013) },
    ];

    return (
        <animated.group rotation-y={rotationYGold} position={props.position} scale={props.windowWidth && props.windowWidth < 1060 ? props.scale + 4.5 : props.scale+1} ref={ref}>
            {meshes.map((meshProps, index) => (
                <animated.mesh
                    key={index}
                    position={[meshProps.position[0], meshProps.position[1], meshProps.position[2]]}
                    rotation-x={rotationXGold}
                    geometry={nodes2['2'].geometry}
                    scale={meshProps.scale}
                    rotation={meshProps.rotation || [0, 0, 0]}
                >
                    {getMaterial(index)}
                </animated.mesh>
            ))}
        </animated.group>
    );
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/gold/webp/asteroid2.glb`);
