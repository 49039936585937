import logo from "./logo.svg";
import "./App.css";
import Scene from "./Scene"; // comment on maintenance true
import Hud from "./Hud";
import { Canvas, extend } from "@react-three/fiber";
import React, {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
  StrictMode,
} from "react";
import { Color, NoToneMapping } from "three";
import {
  AdaptiveDpr,
  PerformanceMonitor,
  useProgress,
} from "@react-three/drei";
import LoadingScreen from "./LoadingScreen";
import "./fonts/futura-book.otf";
import {
  ChromaticAberration,
  ColorAverage,
  EffectComposer,
  Glitch,
  HueSaturation,
} from "@react-three/postprocessing";
import BlurryCursor from "./BlurryCursor";
import {
  FilmPass,
  WaterPass,
  UnrealBloomPass,
  LUTPass,
  LUTCubeLoader,
} from "three-stdlib";
import Stars from "./Objects/Extras/Stars";
import { BrowserView } from "react-device-detect";
import LensFlare from "./Objects/Effects/UltimateLensFlare";
import { getPerf, Perf, PerfHeadless, usePerf } from "r3f-perf";
import Cookies from "./Cookies";
import EventListener from "./EventListener";
import { BlendFunction, GlitchMode } from "postprocessing";
extend({ WaterPass, UnrealBloomPass, FilmPass, LUTPass });

function App() {
  const { progress } = useProgress();
  const [inFocus, setInFocus] = useState("");
  const controlsRef = useRef(null);
  const lightDaoRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [axisOpacity, setAxisOpacity] = useState(0.1);
  const [isActive, setIsActive] = useState(null);

  const [animationUnknown, setAnimationUnknown] = useState(false); // false = normal, true = greenish color, slower speed
  const [animationSun, setAnimationSun] = useState(false); // false = normal, true = big sun rays
  const [animationGlitch, setAnimationGlitch] = useState(false); // must be inserted the postprocessing code
  const [animationCanvasFilter, setAnimationCanvasFilter] = useState(null); // for events: blood bath, alien etc - must be inserted the postprocessing code
  const [animationSaturn, setAnimationSaturn] = useState(false); // false = Good, true = Bad
  const [animationGold, setAnimationGold] = useState(3); // set number of diamonds depending on total value inside SC
  const [animationRing, setAnimationRing] = useState(false); // true = particles ON, false = nothing
  const [animationEdge, setAnimationEdge] = useState(false); // true = particles ON, false = nothing
  const [animationAlertEarth, setAnimationAlertEarth] = useState(true); // earth notif ON = true, OFF = false

  // memoization of the LensFlare configuration !!!! - careful to not delete
  const memoizedLensFlare = useMemo(
    () => (
      <LensFlare
        position={[0, 0, 0]}
        enabled
        opacity={animationSun ? 0.3 : 0.2}
        glareSize={0.000002}
        starPoints={animationSun ? 6 : 0}
        animated
        anamorphic
        followMouse={false}
        flareSpeed={animationSun ? 1.5 : 0.4}
        flareShape={animationSun ? 0.3 : 0.4}
        flareSize={animationSun ? 0.01 : 0.0001}
        secondaryGhosts
        ghostScale={0.001}
        aditionalStreaks
        starBurst={true} // careful at gpu, intensive :)
        haloScale={0.4}
        colorGain={
          animationSun ? new Color(255, 255, 255) : new Color(255, 213, 0)
        }
        // {...lensFlareProps}
      />
    ),
    [windowWidth, animationSun]
  );

  const audioRef = useRef();

  // audio ref
  const audioGlitch = React.useRef(null);

  //  play or pause sound based on animationGlitch state
  useEffect(() => {
    if (audioGlitch.current) {
      if (animationGlitch) {
        audioGlitch.current.volume = 0.2;
        audioGlitch.current.play();
      } else {
        audioGlitch.current.pause();
        audioGlitch.current.currentTime = 0; // rr playback pos
      }
    }
  }, [animationGlitch]);

  const [dpr, setDpr] = useState(window.devicePixelRatio || 1);
  const [isPostProcessingEnabled, setIsPostProcessingEnabled] = useState(false);
  const [performanceData, setPerformanceData] = useState({ fps: 0 });

  const handlePerformanceChange = (data) => {
    setPerformanceData(data);
    setIsPostProcessingEnabled(data.fps > 90);
  };

  const sound = useRef();
  const underMaintenance = false;

  useEffect(()=>{
    console.log("Created by @eFlavvian and @thepixelspell, under the supervision of @AnduTabacu.")
  },[])

  return (
    <>
      <EventListener
        setAnimationUnknown={setAnimationUnknown}
        setAnimationSun={setAnimationSun}
        setAnimationGlitch={setAnimationGlitch}
        setAnimationCanvasFilter={setAnimationCanvasFilter}
        setAnimationSaturn={setAnimationSaturn}
        setAnimationGold={setAnimationGold}
        setAnimationRing={setAnimationRing}
        setAnimationEdge={setAnimationEdge}
        setAnimationAlertEarth={setAnimationAlertEarth}
      />

      {underMaintenance ? (
        <>
          {windowWidth > 1060 ? (
            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
                fontSize: "3vw",
                flexDirection: "column",
                cursor: "default",
              }}
            >
              <div style={{ cursor: "default" }}>All good, but</div>
              <div style={{ cursor: "default" }}>#maintenance</div>
              <div style={{ cursor: "default" }}>😂</div>
            </div>
          ) : (
            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
                fontSize: "7vw",
                flexDirection: "column",
                cursor: "default",
              }}
            >
              <div style={{ cursor: "default" }}>All good, but</div>
              <div style={{ cursor: "default" }}>#maintenance</div>
              <div style={{ cursor: "default" }}>😂</div>
            </div>
          )}
        </>
      ) : (
        <>
          <BrowserView>
            <BlurryCursor isActive={isActive} />
          </BrowserView>

          <LoadingScreen windowWidth={windowWidth} progress={progress} />
          <Cookies windowWidth={windowWidth} progress={progress} />
          <Hud
            sound={sound}
            setAnimationRing={setAnimationRing}
            animationRing={animationRing}
            animationGold={animationGold}
            setAnimationGold={setAnimationGold}
            setAnimationCanvasFilter={setAnimationCanvasFilter}
            setAnimationGlitch={setAnimationGlitch}
            animationGlitch={animationGlitch}
            setAnimationSun={setAnimationSun}
            animationUnknown={animationUnknown}
            animationSaturn={animationSaturn}
            animationSun={animationSun}
            setAnimationEdge={setAnimationEdge}
            animationEdge={animationEdge}
            setAnimationSaturn={setAnimationSaturn}
            setAnimationUnknown={setAnimationUnknown}
            setIsActive={setIsActive}
            setAxisOpacity={setAxisOpacity}
            windowWidth={windowWidth}
            setInFocus={setInFocus}
            controlsRef={controlsRef}
            inFocus={inFocus}
          />

          <StrictMode>
            <Canvas
              fallback={
                <div
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    fontSize: 30,
                  }}
                >
                  On your system WebGL is not supported!
                </div>
              }
              dpr={dpr}
              gl={{
                antialias: false,
                toneMapping: NoToneMapping,
                powerPreference: "high-performance",
                alpha: false,
                stencil: false,
                depth: true,
              }}
              linear
              className="canvas"
              style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                top: 0,
                transform: "translateZ(0)",
                willChange: "transform",
              }}
            >
              <PerfHeadless
                position="top-left"
                onChange={handlePerformanceChange}
              />

              <PerformanceMonitor ms={150} onChange={handlePerformanceChange}>
                <Suspense fallback={null}>
                  <Scene
                    sound={sound}
                    setAnimationRing={setAnimationRing}
                    animationRing={animationRing}
                    setAnimationGold={setAnimationGold}
                    animationGold={animationGold}
                    audioRef={audioRef}
                    setAnimationUnknown={setAnimationUnknown}
                    setAnimationSaturn={setAnimationSaturn}
                    animationSaturn={animationSaturn}
                    animationEdge={animationEdge}
                    setAnimationEdge={setAnimationEdge}
                    animationUnknown={animationUnknown}
                    setIsActive={setIsActive}
                    axisOpacity={axisOpacity}
                    setAxisOpacity={setAxisOpacity}
                    windowWidth={windowWidth}
                    animationAlertEarth={animationAlertEarth}
                    lightDaoRef={lightDaoRef}
                    controlsRef={controlsRef}
                    setInFocus={setInFocus}
                    inFocus={inFocus}
                  />
                </Suspense>
                <Stars color={"white"} radius={10} size={0.05} noStars={1000} />

                {isPostProcessingEnabled && windowWidth > 1060 && (
                  <EffectComposer disableNormalPass multisampling={false}>
                    {memoizedLensFlare}
                    {animationGlitch && (
                      <Glitch
                        delay={0} // min / max
                        duration={1} // min and max glitch duration
                        strength={[0.3, 1.0]} // min and max glitch strength
                        mode={GlitchMode.SPORADIC}
                        active
                        ratio={0.85} // threshold glitches, 0 - no weak glitches, 1 - strong
                      />
                    )}

                    {animationCanvasFilter === "1" && (
                      <HueSaturation
                        visible={animationCanvasFilter === "1"}
                        saturation={2}
                      />
                    )}

                    {animationCanvasFilter === "2" && (
                      <HueSaturation
                        visible={animationCanvasFilter === "2"}
                        saturation={0.3}
                      />
                    )}

                    {animationCanvasFilter === "3" && (
                      <HueSaturation
                        visible={animationCanvasFilter === "3"}
                        saturation={5}
                      />
                    )}

                    {animationCanvasFilter === "4" && (
                      <ChromaticAberration
                        visible={animationCanvasFilter === "4"}
                        blendFunction={BlendFunction.DARKEN}
                        offset={[0.003, 0.003]}
                      />
                    )}

                    {animationCanvasFilter === "5" && (
                      <ColorAverage
                        visible={animationCanvasFilter === "5"}
                        blendFunction={BlendFunction.HUE}
                      />
                    )}

                    {animationCanvasFilter === "6" && (
                      <ColorAverage
                        visible={animationCanvasFilter === "6"}
                        blendFunction={BlendFunction.ALPHA}
                      />
                    )}
                  </EffectComposer>
                )}

                <AdaptiveDpr pixelated />
              </PerformanceMonitor>
            </Canvas>
          </StrictMode>
          <audio ref={audioGlitch} src="glitch.mp3" preload="auto" />
          <video
            id={"advert"}
            muted
            autoPlay
            playsInline
            style={{ display: "none" }}
            src="presentation.mp4"
            onClick={(e) => e.preventDefault()}
          />
        </>
      )}
    </>
  );
}

export default App;
