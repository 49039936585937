import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { MeshStandardMaterial } from 'three';
import { useGLTF } from '@react-three/drei';
import RingParticles from './RingParticles';

export default function AnimatedRing(props) {
  const ringRef = useRef();

  const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/ring/webp/Ring.glb`);

  // Use memoization to avoid unnecessary function re-creations
  const moveCamera = React.useCallback(() => {
    document.title = "ProjectX • Ring";
    props.setInFocus("ring");
    if (props.windowWidth > 1060) {
      props.controlsRef.current?.setLookAt(3.4, 0.15, 10, 3.4, 0.15, 1, true);
    }
  }, [props]);

  // Use useFrame efficiently
  useFrame((state, delta) => {
    if (ringRef.current) {
      if (props.animationRing)
        ringRef.current.rotation.y += delta * 0.3;
      else
        ringRef.current.rotation.y += delta * 0.05;

    }
  });

  return (
    <group
      ref={ringRef}
      dispose={null}
      {...props}
    >
      {/* Sphere using custom shader */}
      {
        props.animationRing && props.windowWidth > 1060 ? (<RingParticles count={3000} animation={props.animationRing} />) : null
      }

      {/* Mesh with reduced material properties */}
      <mesh geometry={nodes.ringGlow.geometry}>
        <meshStandardMaterial color="cyan" emissive="cyan" emissiveIntensity={1} />
      </mesh>

      <mesh
        onPointerEnter={() => props.setIsActive("Ring")}
        onPointerLeave={() => props.setIsActive(null)}
        onClick={moveCamera}
        geometry={nodes.ring.geometry}>
        <meshStandardMaterial
          map={materials['Material.001'].map}
          metalness={0.7}
          roughness={0.7}
        />
      </mesh>
    </group>
  );
}

// Preload GLTF model for better performance
useGLTF.preload(`${process.env.PUBLIC_URL}/v2/ring/webp/Ring.glb`);
