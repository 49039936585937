import logo from '../../../logo.svg';
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../../Lights';
import { MeshBasicMaterial, MeshDepthMaterial, MeshStandardMaterial, PlaneGeometry, ShaderMaterial, SphereGeometry, WebGLRenderTarget } from 'three';

import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { useEffect, useRef } from 'react';


// Custom shader material with color dodge blending
const ColorDodgeMaterial = {
  uniforms: {
    tDiffuse: { value: null },
    tBlend: { value: null },
  },
  vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 0.93);
      }
    `,
  fragmentShader: `
      uniform sampler2D tDiffuse;
      uniform sampler2D tBlend;
      varying vec2 vUv;
  
      void main() {
        vec4 base = texture2D(tDiffuse, vUv);
        vec4 blend = texture2D(tBlend, vUv);
  
        // Color dodge blending
        vec3 color = base.rgb / max(1.0 - blend.rgb, 0.05);
        gl_FragColor = vec4(color, base.a);
      }
    `,
};

extend({ ColorDodgeMaterial });

export default function Rail(props) {

  const material = new MeshBasicMaterial({ map: props.textures.rail, transparent: true });

  return (
    <>

      {
        props.windowWidth > 1060 ? (<>
          <Plane args={[2, 2]} position={props.position} material={material} rotation={[0, -0.1, -0.08]} scale={props.scale} />
        </>) : (<>
          <Plane args={[2, 2]} position={props.position} material={material} rotation={[0, 0, -0.6]} scale={props.scale} />
        </>)
      }

    </>
  );
}