import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere } from '@react-three/drei';
import Lights from '../../Lights';
import { LinearFilter, MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, SRGBColorSpace, TextureLoader, VideoTexture } from 'three';
import CloudsXID from './CloudsXID';
import LinesXID from './LinesXID';
import Advert from './Advert';
import AdvertWebcam from './AdvertWebcam';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import F1Xid from './F1Xid';
import { useSpring, animated } from '@react-spring/three';
import Working from './Working';

export default function XID(props) {

    const video = document.getElementById('advert');

    video.volume = 0.05;
    const videoTexture = new VideoTexture(video);
    videoTexture.colorSpace = SRGBColorSpace;
    videoTexture.minFilter = LinearFilter;
    videoTexture.repeat.set(1, 1);


    const { rotationY: rotationYXID } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: Math.PI * 2 },
        config: { duration: 90000, easing: t => t },
        loop: true,
    });

    const pressObj = () => {
        props.setInFocus("xid");
        document.title = "ProjectX • XID";
        if (props.controlsRef)
            props.controlsRef.current?.setLookAt(-2.9, 2.2, 6, -2.9, 2.2, -4, true); // setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition = false)
    }

    return (
        <>
            <Advert setIsActive={props.setIsActive} windowWidth={props.windowWidth} position={[props.position[0] - 0.3, props.position[1] - 0.05, props.position[2] + 0.5]} scale={props.scale} rotation={[0.15, -0.8, -1.6]} />
            {/* <AdvertWebcam setIsActive={props.setIsActive} position={[props.position[0] - 0.3, props.position[1] - 0.05, props.position[2] + 0.5]} scale={0.3} rotation={[0.15, -0.8, -1.6]} /> */}

            <group position={props.position} rotation={[0, -0.8, 0]}>
                <Working scale={props.scale * 0.2} rotation={[0, -1, 0]} position={props.windowWidth < 1060 ? [0, 1.2, 0] : [0, 0.45, 0]} />
                <animated.mesh
                    renderOrder={1}
                    onPointerEnter={() => { props.setIsActive("XID") }}
                    onPointerLeave={() => { props.setIsActive(null) }}
                    rotation-y={rotationYXID} onClick={() => { pressObj() }} position={[0, 0, 0]} geometry={props.sphere} scale={props.scale} >
                    <sphereGeometry args={[1, 40, 40]} /> {/* Example with radius 1 and 32 segments */}
                    {/* <meshBasicMaterial map={videoTexture} wireframe transparent opacity={0.3} /> */}
                    <meshStandardMaterial map={props.textures.textureXid} transparent opacity={1} />
                </animated.mesh>

                <mesh renderOrder={2} position={[0, 0, 0]} geometry={props.sphere} scale={props.scale + 0.03} >
                    <sphereGeometry args={[1.1, 25, 25]} /> {/* Example with radius 1 and 32 segments */}
                    <meshStandardMaterial wireframe opacity={0.2} transparent color={"cyan"} />
                </mesh>
            </group>
        </>
    );
}