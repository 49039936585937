import { MeshPortalMaterial, Stars, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import React, { Suspense, useMemo } from 'react';
import Saturn from './Saturn/Saturn';
import shader from './deepPurple.json'

export default function BlackHole(props) {

    const customShaderMaterial = useMemo(() =>
        new THREE.RawShaderMaterial({
            uniforms: {
                ...shader.uniforms,
                time: { value: 0 }
            },
            vertexShader: shader.vertex,
            fragmentShader: shader.fragment,
            transparent: false,
            // depthWrite: false,
        }), []
    );

    useFrame(({ clock }) => {
        customShaderMaterial.uniforms.time.value = clock.getElapsedTime();
    });

    return (
        <group
            onPointerEnter={() => { props.setIsActive("Wormhole") }}
            onPointerLeave={() => { props.setIsActive(null) }}
            frustumCulled name='Black hole' rotation={[0, 0, 0]} position={[0.3, -2, 0]} scale={0.3}>
            <mesh renderOrder={1} position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[0.8, 1, 1]} material={customShaderMaterial}>
                <sphereGeometry />
            </mesh>
        </group>
    );
}
