import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap';

export default function BlurryCursor(props) {
    const mouse = useRef({ x: 0, y: 0 });
    const circle = useRef();

    const size = props.isActive ? 50 : 20;


    const manageMouseMove = (e) => {
        const { clientX, clientY } = e;

        mouse.current = {
            x: clientX,
            y: clientY
        }

        moveCircle(mouse.current.x, mouse.current.y);
    }

    const moveCircle = (x, y) => {
        gsap.set(circle.current, { x, y, xPercent: -50, yPercent: -50 })
    }

    useEffect(() => {
        window.addEventListener("mousemove", manageMouseMove);
        return () => {
            window.removeEventListener("mousemove", manageMouseMove);
        }
    }, [])


    useEffect(() => {
        // animate();
        window.addEventListener("mousemove", manageMouseMove);
        return () => {
            window.removeEventListener("mousemove", manageMouseMove);
            // window.cancelAnimationFrame(rafId.current)
        }
    }, [props.isActive])

    return (
        <div style={{ position: 'fixed', height: '100%', width: '100%', zIndex: 99999, userSelect: 'none', pointerEvents: 'none' }}>
            <div
                ref={circle}
                style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    // backdropFilter: 'blur(5px)',
                    // WebkitBackdropFilter: 'blur(5px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: props.isActive ? size : size * 0.7,
                    height: props.isActive ? size : size * 0.7,
                    borderRadius: 500,
                    // filter: `blur(${props.isActive ? 20 : 0}px)`,
                    transition: `height 0.3s ease-out, width 0.3s ease-out, filter 0.3s ease-out`
                }}
            >

                <div
                    style={{
                        backgroundColor: "rgba(0,0,0,1)",
                        // backdropFilter: 'blur(5px)',
                        // WebkitBackdropFilter: 'blur(5px)',
                        width: props.isActive ? size * 0.8 : 0,
                        height: props.isActive ? size * 0.8 : 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 500,
                        // backdropFilter: `blur(${props.isActive ? 40 : 0}px)`,
                        transition: `height 0.3s ease-out, width 0.3s ease-out, filter 0.3s ease-out`
                    }}
                >
                    <img style={{ position: 'absolute', width: '100%', transform: 'scale(0.7)', display: props.isActive ? "flex" : "none" }} src='logo.svg' />
                    <div className={props.isActive ? "planetPlaceholder show" : "planetPlaceholder"} style={{ position: 'realtive', marginBottom: '-6rem', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minWidth: '4rem' }}>{props.isActive}</div>
                </div>
            </div>
        </div>
    )
}