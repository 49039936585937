import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, shaderMaterial, Sphere, useGLTF, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { AdditiveBlending, DoubleSide, MeshBasicMaterial, MeshStandardMaterial, RepeatWrapping, SphereGeometry, TorusGeometry, Color, Vector3, ShaderMaterial } from 'three';
import { useSpring, animated } from '@react-spring/three';
import glsl from 'babel-plugin-glsl/macro';
import { useEffect, useMemo, useRef, useState } from 'react';

export default function Saturn(props) {

    const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/saturn/webp/saturnRing2.gltf`);
    const { nodes: nodesExclamation, materials: materialsExclamation } = useGLTF(`${process.env.PUBLIC_URL}/v2/saturn/webp/exclamation.glb`)

    // Texture properties
    props.textures.ringSaturn.wrapS = props.textures.ringSaturn.wrapT = RepeatWrapping;
    props.textures.ringSaturn.repeat.set(3, 3);
    props.textures.ringSaturn.offset.set(0, 0.53);

    const saturnTexture = new MeshStandardMaterial({ map: props.textures.textureSaturn, side: DoubleSide });
    const saturnClouds = new MeshBasicMaterial({ map: props.textures.cloudsSaturn, transparent: true });
    const sattelites = new MeshBasicMaterial({ map: props.textures.sattelites, transparent: true, side: DoubleSide });
    const sattelitesDOWN = new MeshBasicMaterial({ map: props.textures.sattelitesDOWN, transparent: true, side: DoubleSide });

    const { rotationY: rotationYSaturn } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 60000, easing: t => t },
        loop: true,
    });

    const moveCamera = () => {
        props.setInFocus("saturn");
        document.title = "ProjectX • Saturn";
        if (props.windowWidth > 1060) {
            props.controlsRef.current?.setLookAt(-3.5, 0.25, 8, -3.5, 0.25, 1, true);
        }
    };

    // useEffect(() => {
    //     console.log("Material used: ", materials.Mat);
    // }, [materials.Mat]);

    const fragmentShader_old = `
    uniform sampler2D u_texture;
    uniform vec3 u_lightPosition;
    uniform vec3 u_cameraPosition;
    uniform float u_metalness;
    uniform float u_roughness;
    uniform bool u_toneMapped;
    uniform float u_red;

    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;

    void main() {
        vec4 textureColor = texture(u_texture, vUv);
        vec3 textureRGB = textureColor.rgb;

        vec3 normal = normalize(vNormal);
        vec3 lightDir = normalize(u_lightPosition - vViewPosition);
        float diff = max(dot(normal, lightDir), 0.0);

        vec3 viewDir = normalize(u_cameraPosition - vViewPosition);
        vec3 reflectDir = reflect(-lightDir, normal);
        float spec = pow(max(dot(viewDir, reflectDir), 0.0), 32.0);

        vec3 ambient = vec3(0.1);
        vec3 diffuse = diff * vec3(1.0);
        vec3 specular = spec * vec3(1.0);

        vec3 lightingColor = ambient + (1.0 - u_metalness) * diffuse + u_metalness * specular;

        if (u_toneMapped) {
            lightingColor = lightingColor / (lightingColor + vec3(1.0));
        }

        vec3 redTint = vec3(1.0, 0.0, 0.0);
        vec3 colorWithRed = mix(textureRGB, redTint, u_red);
        vec3 finalColorRGB = colorWithRed * lightingColor;

        vec4 finalColor = vec4(finalColorRGB, textureColor.a);
        gl_FragColor = finalColor;
    }
    `;

    const fragmentShader = `
        uniform sampler2D u_texture;
        uniform vec3 u_lightPosition;
        uniform vec3 u_cameraPosition;
        uniform float u_metalness;
        uniform float u_roughness;
        uniform bool u_toneMapped;
        uniform float u_red;
        uniform vec3 u_sideDirection; // Direction vector to distinguish the sides
        
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vViewPosition;
        
        void main() {
            // Sample the texture color
            vec4 textureColor = texture(u_texture, vUv);
            vec3 textureRGB = textureColor.rgb;
        
            // Compute lighting
            vec3 normal = normalize(vNormal);
            vec3 lightDir = normalize(u_lightPosition - vViewPosition);
            float diff = max(dot(normal, lightDir), 0.0);
        
            vec3 viewDir = normalize(u_cameraPosition - vViewPosition);
            vec3 reflectDir = reflect(-lightDir, normal);
            float spec = pow(max(dot(viewDir, reflectDir), 0.0), 32.0);
        
            vec3 ambient = vec3(0.05);
            vec3 diffuse = diff * vec3(1.0);
            vec3 specular = spec * vec3(1.0);
        
            vec3 lightingColor = ambient + (1.0 - u_metalness) * diffuse + u_metalness * specular;
        
            if (u_toneMapped) {
                lightingColor = lightingColor / (lightingColor + vec3(1.0));
            }
        
            vec3 redTint = vec3(1.0, 0.0, 0.0);
            vec3 colorWithRed = mix(textureRGB, redTint, u_red);
            if (u_red == 1.0) {
                colorWithRed = redTint; // Full red when u_red is 1
            }
            vec3 finalColorRGB = colorWithRed * lightingColor;
        
            vec4 litColor = vec4(finalColorRGB, textureColor.a);
        
            // Determine the blending factor based on side direction
            float sideFactor = dot(normalize(vNormal), normalize(u_sideDirection));
            sideFactor = smoothstep(-0.4, 0.0, sideFactor); // Adjust to control blending threshold
        
            // Blend between the basic texture and the lit texture
            vec4 finalColor = mix(textureColor, litColor, sideFactor);
        
            gl_FragColor = finalColor;
        }
        
    `;

    const vertexShader = `
        uniform float u_time;
        uniform float u_amplitude;

        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vViewPosition;

        void main() {
            vUv = uv;
            vNormal = normalize(normalMatrix * normal);
            vec4 modelPosition = modelMatrix * vec4(position, 1.0);

            modelPosition.y += sin(modelPosition.x * 10.0 + u_time * 1.0) * u_amplitude;

            vec4 viewPosition = viewMatrix * modelPosition;
            vViewPosition = viewPosition.xyz;

            gl_Position = projectionMatrix * viewPosition;
        }
    `;

    const mesh = useRef();
    const meshExclamation = useRef();

    const shaderMaterial = useMemo(() => new ShaderMaterial({
        fragmentShader,
        vertexShader,
        uniforms: {
            u_time: { value: 1 },
            u_amplitude: { value: 0.03 },
            u_red: { value: props.animationSaturn ? 0.0 : 0.5 },
            u_texture: { value: materials.Mat.map },
            u_lightPosition: { value: new Vector3(0, 0, 1) },
            u_cameraPosition: { value: new Vector3(0, 0, 5) },
            u_metalness: { value: 0 },
            u_roughness: { value: 1 },
            u_toneMapped: { value: true },
            u_sideDirection: { value:  props.animationSaturn ? new Vector3(0.0, 0.0, 1.0) : new Vector3(-1.0, 1.0, 0.0) } // direction for lighter one
        }
    }), [materials.Mat.map, props.animationSaturn]);

    useFrame((state, delta) => {
        if (mesh.current) {
            mesh.current.material.uniforms.u_time.value += delta;

            if (props.animationSaturn) {
                mesh.current.rotation.y += delta * 0.02;
                mesh.current.material.uniforms.u_amplitude.value = 0.0;
                mesh.current.material.uniforms.u_red.value = 1.0;
                mesh.current.material.uniforms.u_roughness.value = 1.0;
                mesh.current.material.uniforms.u_metalness.value = 0.0;
                mesh.current.material.uniforms.u_sideDirection.value = new Vector3(0.0, 0.0, 1.0);
            } else {
                mesh.current.rotation.y += delta * 0.3;
                mesh.current.material.uniforms.u_amplitude.value = 0.03;
                mesh.current.material.uniforms.u_red.value = 0.0;
                mesh.current.material.uniforms.u_roughness.value = 0.2;
                mesh.current.material.uniforms.u_metalness.value = 0.5;
                mesh.current.material.uniforms.u_sideDirection.value = new Vector3(-1.0, 1.0, 0.0);
            }

            mesh.current.material.needsUpdate = true;
        }
        if (meshExclamation && meshExclamation.current) {
            meshExclamation.current.rotation.z += delta;
        }
    });

    return (
        <>
            <group frustumCulled>
                <animated.mesh
                    renderOrder={4}
                    position={[props.position[0]-0.1, props.position[1]+0.1, props.position[2]+0.2]}
                    geometry={props.sphere}
                    material={props.animationSaturn ? sattelitesDOWN : sattelites}
                    scale={props.scale - 0.12}
                />
                <animated.mesh
                    renderOrder={1}
                    rotation-y={rotationYSaturn}
                    position={props.position}
                    geometry={props.sphere}
                    material={saturnClouds}
                    scale={props.scale + 0.001}
                />
                <mesh
                    ref={mesh}
                    renderOrder={2}
                    geometry={nodes.saturnRing.geometry}
                    position={[props.position[0], props.position[1] - 0.03, props.position[2]]}
                    rotation={[0.2, 0, 0.1]}
                    scale={props.windowWidth > 1060 ? 2 : 6}
                >
                    <primitive object={shaderMaterial} attach="material" />
                </mesh>
                {
                    props.animationSaturn ? (

                        <mesh
                            ref={meshExclamation}
                            position={props.windowWidth > 1060 ? [props.position[0], props.position[1] + 0.5, props.position[2]] : [props.position[0], props.position[1] + 1.7, props.position[2]]}
                            geometry={nodesExclamation.Text.geometry}
                            scale={props.windowWidth > 1060 ? 0.25 : 1}
                            rotation={[Math.PI / 2, 0, 0]}
                        >
                            <meshStandardMaterial color={"red"} metalness={0.6} roughness={0.5} />
                        </mesh>

                    ) : null
                }

                <animated.mesh
                    onPointerEnter={() => { props.setIsActive("Saturn"); }}
                    onPointerLeave={() => { props.setIsActive(null); }}
                    onClick={() => { moveCamera(); }}
                    rotation-y={rotationYSaturn}
                    position={props.position}
                    geometry={props.sphere}
                    material={saturnTexture}
                    scale={props.scale}
                />
            </group>
        </>
    );
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/saturn/webp/saturnRing2.gltf`);
useGLTF.preload(`${process.env.PUBLIC_URL}/v2/saturn/webp/exclamation.glb`);