import React, { useRef } from 'react'
import { Caustics, CubeCamera, MeshRefractionMaterial, MeshTransmissionMaterial, useGLTF } from '@react-three/drei'
import { MeshBasicMaterial, MeshStandardMaterial } from 'three';
import { useLoader } from '@react-three/fiber';
import { RGBELoader } from 'three-stdlib';
import { useSpring, animated } from '@react-spring/three';
import { useControls } from 'leva';

export function Asteroid1(props) {
    const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/gold/webp/asteroid1.glb`)

    const ref = useRef()
    const asteroidMaterial = new MeshBasicMaterial({
        transparent: true,
    });

    const { rotationY: rotationYGold } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 90000, easing: t => t },
        loop: true,
    });

    const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr')
    return (
        <group {...props} dispose={null}
            position={props.position}>
            <CubeCamera resolution={60} frames={50} envMap={texture}>
                {(texture) => (
                    <Caustics
                        backfaces
                        position={[0, -0.5, 0]}
                        lightSource={[5, 5, -10]}
                        worldRadius={0.1}
                        ior={1.8}
                        backfaceIor={1.1}
                        intensity={0}>
                        <animated.mesh rotation-y={rotationYGold}  ref={ref} geometry={nodes['1'].geometry} {...props}>
                            <MeshRefractionMaterial envMap={texture} toneMapped={false} />
                        </animated.mesh>
                    </Caustics>
                )}
            </CubeCamera>
        </group>
    )
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/gold/webp/asteroid1.glb`)