import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { Html, Image, PerspectiveCamera, PivotControls, Plane, Sphere, Text, useGLTF, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { DoubleSide, MeshBasicMaterial, MeshStandardMaterial, SphereGeometry } from 'three';
import { useSpring, animated } from '@react-spring/three';

export default function Spaceport(props) {
  const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/spaceport/spaceport2.glb`)

  const rsStandard = new MeshStandardMaterial({
    map: materials.Mat.map,
    opacity: 1
  });

  const { rotationY: rotationYCercMare } = useSpring({
    from: { rotationY: 0 },
    to: { rotationY: 0.3 },
    config: { duration: 9000, easing: t => t },
    loop: { reverse: true },
  });

  const { rotationZ: rotationZCercMare } = useSpring({
    from: { rotationZ: 0 },
    to: { rotationZ: Math.PI * 2 },
    config: { duration: 100000, easing: t => t },
    loop: true,
  });

  const { rotationY: rotationYCercMediu } = useSpring({
    from: { rotationY: 0 },
    to: { rotationY: 0.2 },
    config: { duration: 6000, easing: t => t },
    loop: { reverse: true },
  });

  const { rotationZ: rotationZCercMic } = useSpring({
    from: { rotationZ: 0 },
    to: { rotationZ: Math.PI * 2 },
    config: { duration: 70000, easing: t => t },
    loop: true,
  });


  const moveCamera = () => {
    props.setInFocus("spaceport");
    document.title = "ProjectX • Spaceport";
    //props.setAxisOpacity(0);
    if (props.windowWidth > 1060) {
      props.controlsRef.current?.setLookAt(1.5, -1.25, 11, 1.5, -1.25, 1, true);
    }
  }

  const moveCameraInside = () => {
    props.setInFocus(null);
    //props.setAxisOpacity(0);
    if (props.windowWidth > 1060) {
      props.controlsRef.current?.setLookAt(2.723, -1.06, -2, 2.723, -1.06, 1, true);
      props.controlsRef.current?.setPosition(2.645, -1.06, 1.3, true);
      // console.log(props.controlsRef.current);
    }
  }

  return (
    <group {...props}
      rotation={[0, -0.3, 0]}
      dispose={null}>
      <animated.mesh
        geometry={nodes.box_1.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <animated.mesh
        geometry={nodes.box_2.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <animated.mesh
        geometry={nodes.box_3.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <animated.mesh
        geometry={nodes.box_4.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />

      <animated.mesh
        rotation-y={rotationYCercMare}
        rotation-z={rotationZCercMare}
        geometry={nodes.cerc_mare.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <animated.mesh
        rotation-y={rotationYCercMediu}
        geometry={nodes.cerc_mediu.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <animated.mesh
        position={[0, 10, 0]}
        rotation-z={rotationZCercMic}
        geometry={nodes.Cerc_mic.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2 * 0.9, 0.4, 0]}
      />
      <animated.mesh
        geometry={nodes.Corp_nava.geometry}
        material={rsStandard}
        rotation={[Math.PI / 2, 0, 0]}
      />

      <mesh
        renderOrder={2}
        onPointerEnter={() => { props.setIsActive("Spaceport") }}
        onPointerLeave={() => { props.setIsActive(null) }}
        onClick={() => {
          moveCamera()
        }}
        position={[-11.5, 9.6, 9]} scale={20}>
        <planeGeometry args={[1.5, 3]} />
        <meshStandardMaterial opacity={0} transparent />
      </mesh>

      {/* <mesh
        renderOrder={3}
        onClick={() => {
          moveCameraInside();
        }}
        rotation={[0, 0.6, 0]}
        position={[3.9, 9.5, 5.1]} scale={1}>
        <planeGeometry args={[1.6, 1.3]} />
        <meshStandardMaterial opacity={0} transparent />
      </mesh>

      <Text
        renderOrder={1}
        position={[2.8, 9.6, 6]}
        rotation={[0, 0.63, 0]}
        scale={0.1}
      >
        EG Incoming ...
      </Text>

      <Text
        renderOrder={1}
        position={[2.8, 9.48, 6]}
        rotation={[0, 0.63, 0]}
        scale={0.06}
      >
        [area under development]
      </Text>

      <Image url='https://media.xoxno.com/nftmedia/SUBJECTX-2c184d/SUBJECTX-2c184d-1539.avif' position={[4, 9.48, -7]} scale={0.7} /> */}

    </group>
  )
}


useGLTF.preload(`${process.env.PUBLIC_URL}/v2/spaceport/spaceport2.glb`)




