
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshBasicMaterial } from 'three';

export default function F2Xid(props) {
    const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/factions/f2.glb`)
    const material = new MeshBasicMaterial({ map: materials['default'].map, transparent: true});
    
    return (
        <group {...props} dispose={null}>
            <mesh
                
                
                position={props.position}
                scale={props.scale}
                geometry={nodes.Faction_model.geometry}
                material={material}
                rotation={props.rotation}
            />
        </group>
    )
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/factions/f2.glb`)
