import React, { useRef } from 'react';
import { useMemo } from 'react';
import { Group, SphereGeometry, MeshBasicMaterial, Mesh, PlaneGeometry } from 'three';
import { extend, useFrame } from '@react-three/fiber';
import { LayerMaterial, Depth, Fresnel } from "lamina";
import CustomLayer from './CustomLayer'
import { VideoTexture } from 'three';
import { LinearFilter } from 'three';
import { SRGBColorSpace } from 'three';
import { Vector2 } from 'three';
import { AdditiveBlending } from 'three';
import { useEffect } from 'react';

import vertexShader from './shadersAdvert/vertexShader';
import fragmentShader from './shadersAdvert/fragmentShader';
import gsap from 'gsap';

export default function Advert(props) {
    const groupRef = useRef();
    const video = document.getElementById('advert');
    useEffect(() => {
        if (video) {
            video.muted = true;
            video.volume = 0.05;
            video.play();
        }
    }, [video])
    const videoTexture = new VideoTexture(video);
    videoTexture.colorSpace = SRGBColorSpace;
    videoTexture.minFilter = LinearFilter;

    const mesh = useRef();
    const uniforms = useMemo(
        () => ({
            u_time: {
                value: 1,
            },
            u_texture: {
                value: videoTexture,
            },
            u_curvature: {
                value: 1,
            }
        }), []
    );

    useFrame((state) => {
        const { clock } = state;
        mesh.current.material.uniforms.u_time.value = clock.getElapsedTime();
    });

    return (
        <group ref={groupRef} position={props.windowWidth < 1060 ? [props.position[0] - 0.2, props.position[1], props.position[2] + 0.9] : props.position} rotation={props.rotation} scale={props.scale}>
            <mesh renderOrder={1} ref={mesh}
            >
                <planeGeometry args={[1.2, 2.3, 10, 10]} />
                <shaderMaterial
                    transparent
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={uniforms}
                // wireframe
                />
            </mesh>

            {/* small right */}
            <mesh renderOrder={3} position={[0.4, 1, -1.2]} rotation={[-1.8, 0, 0]}>
                <planeGeometry args={[0.5, 1.6, 10, 10]} />
                <shaderMaterial
                    transparent
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={uniforms}
                // wireframe
                />
            </mesh>
        </group>
    );
};

