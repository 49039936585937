
import { useEffect } from 'react';
import './App.css';

export default function EventListener(props) {

    useEffect(() => {
        props.setAnimationUnknown(true);// false = normal, true = greenish color, slower speed
        props.setAnimationSun(false); // false = normal, true = big sun rays
        // props.setAnimationCanvasFilter("0")// for events: blood bath, alien etc - must be inserted the postprocessing code, for now disabled.
        props.setAnimationSaturn(false)// false = Good, true = Bad
        props.setAnimationGold(10000)// set number of diamonds depending on total value inside SC
        props.setAnimationRing(true) // true = particles ON, false = nothing
        props.setAnimationEdge(false)// true = red ON + speed, false = nothing
        props.setAnimationAlertEarth(true) // true = earth notification ON, false = earth notification OFF
        props.setAnimationGlitch(false) // true = earth notification ON, false = earth notification OFF
    }, [])


    //Note: tot ce are process.env.PUBLIC_URL, ar trebui pus in S3 cu link relativ.
    //Copy&paste rewrites and redirects: </^[^.]+$|\.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json|webp|webm|glb|gltf|mp4|mp3|mov|otf)$)([^.]+$)/>

    return null;
}


