import './App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Plane, PresentationControls, useTexture } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import logo from './logo.png'
import { useEffect, useState } from 'react';

function setCookie(name, value, days) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
}

function getCookie(name) {
    const cookie = document.cookie.split('; ').find(v => v.startsWith(name + '='));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
}
export default function Cookies(props) {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (props.progress > 99.9) { // if it's loaded, add the animation
            setTimeout(() => {
                const cookies = getCookie('acceptedCookies');
                //console.log("cookies:", cookies)
                if (!cookies)
                    setLoaded(true);

            }, 2500)
        }
    }, [props.progress])


    const acceptCookies = () => {
        setCookie("acceptedCookies", true, 365);
        setLoaded(false);
    }


    return (
        <>
            {
                props.windowWidth > 1060 ? (<>
                    {
                        loaded ? (<>

                            <div style={{ position: 'absolute', width: '100%', bottom: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: 40, paddingLeft: '1rem', paddingRight: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', bottom: '1.5rem', left: 0, zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    We use cookies to enhance your experience. By continuing, you consent to our use of cookies. <div className='understand-cookies' onClick={() => { acceptCookies(); }} style={{ position: 'relative', marginLeft: '1rem', borderRadius: 40 }}>Understood.</div>
                                </div>
                            </div>

                        </>) : null
                    }
                </>) :
                    (<>

                        {
                            loaded ? (<>

                                <div style={{ position: 'absolute', width: '100%', bottom: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ position: 'relative', flexDirection: 'column', backgroundColor: 'white', width: '90%', paddingLeft: '2rem', paddingRight: '2rem', borderRadius: 40, paddingTop: '1rem', paddingBottom: '0.5rem', bottom: '1.5rem', left: 0, zIndex: 9999, display: 'flex', gap: '0.5rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                        We use cookies to enhance your experience. By continuing, you consent to our use of cookies. <div className='understand-cookies' onClick={() => { acceptCookies(); }} style={{ position: 'relative', borderRadius: 40 }}>Understood.</div>
                                    </div>
                                </div>

                            </>) : null
                        }
                    </>)
            }
        </>
    );
}


