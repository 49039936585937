import { Plane } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMemo, useState } from 'react';
import * as THREE from 'three';
import { useSpring, animated } from '@react-spring/three';
import shader from './shader-test.json'

export default function Unknown(props) {

    const textureUnknown = new THREE.MeshStandardMaterial({
        map: props.textures.textureUnknown,
    });

    const moveCamera = () => {
        //props.setAxisOpacity(0);
        props.setInFocus('unknown');
        document.title = "ProjectX • Unknown";
        if (props.windowWidth > 1060) {
            props.controlsRef.current?.setLookAt(0.6, 2, 7, 0.6, 2, 0, true);
        }
    };

    const { rotationY: rotationYUnknown } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 140000, easing: (t) => t },
        loop: true,
    });

    const customShaderMaterial = useMemo(
        () =>
            new THREE.ShaderMaterial({
                uniforms: {
                    ...shader.uniforms,
                    color: { value: props.animationUnknown ? [0, 0.2, 0.5] : [0.03, 0, 1] } // anim - 0.3, 0, 0.5 (red), normal - 0.03, 0, 1
                },
                vertexShader: shader.vertex,
                fragmentShader: shader.fragment,
                transparent: true,
                depthWrite: false,
            }),
        [props.textures.ringUnknown, props.animationUnknown]
    );

    useFrame(({ clock }) => {
        if (props.animationUnknown) {
            customShaderMaterial.uniforms.time.value = clock.getElapsedTime() * 0.5;
        } else {
            customShaderMaterial.uniforms.time.value = clock.getElapsedTime() * 2;
        }
    });


    return (
        <>
            <mesh
                renderOrder={10}
                rotation={[-1, -0.8, 1.8]}
                position={[props.position[0], props.position[1] - 0.02, props.position[2]]}
                scale={props.scale * 0.1}
                material={customShaderMaterial}
            >
                <torusGeometry attach="geometry" args={[16, 5, 2, 100]} />
            </mesh>


            <animated.mesh
                onPointerEnter={() => {
                    props.setIsActive('Unknown');
                }}
                onPointerLeave={() => {
                    props.setIsActive(null);
                }}
                onClick={() => {
                    moveCamera();
                }}
                rotation-y={rotationYUnknown}
                position={props.position}
                geometry={props.sphere}
                material={textureUnknown}
                scale={props.scale}
            />
        </>
    );
}
