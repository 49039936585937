import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere, useScroll } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, RepeatWrapping, SphereGeometry, TextureLoader } from 'three';
import LinesDAO from './LinesDAO';
import { useEffect, useRef, useState } from 'react';
import X from './X';
import OutlineDAO from './OutlineDAO';
import StreamDAO from './StreamDAO';
import gsap from 'gsap';
import { useDrag } from "@use-gesture/react";
import F2Xid from '../XID/F2Xid';
import F1Xid from '../XID/F1Xid';
import F3Xid from '../XID/F3Xid';
import F4Xid from '../XID/F4Xid';
import F5Xid from '../XID/F5Xid';
import F6Xid from '../XID/F6Xid';
import Laser from '../XID/Laser';
import { useSpring, animated } from '@react-spring/three';
import { OBJDAO } from './OBJDAO';

export default function DAO(props) {

    const moveCamera = () => {
        props.setInFocus("dao");
        document.title = "ProjectX • Dao";
        //props.setAxisOpacity(0);
        if (props.windowWidth > 1060)
            props.controlsRef.current?.setLookAt(2.15, 1.2, 10, 2.15, 1.2, 1, true);
    }

    const cameraOnDao = () => {
        if (props.windowWidth > 1060)
            props.controlsRef.current?.setLookAt(2.15, 1.2, 10, 2.15, 1.2, 1, true);
    }



    return (
        <>
            {
                props.windowWidth > 1060 ? (<>

                    {/* ora 1 */}
                    <F1Xid onClick={() => { props.setInFocus("faction-1"); cameraOnDao(); }} scale={props.scale * 0.17} position={[props.position[0] + 0.38, props.position[1] + 0.2, props.position[2]]} rotation={[Math.PI / 2, 0.3, 0.3]} />
                    <Laser textures={props.textures} scale={props.scale * 0.16} position={[props.position[0] + 0.38, props.position[1] + 0.14, props.position[2]]} rotation={[0, 0, -1.3]} />

                    <F2Xid onClick={() => { props.setInFocus("faction-2"); cameraOnDao(); }} scale={props.scale * 0.17} position={[props.position[0] - 0.65, props.position[1] + 0.25, props.position[2]]} rotation={[Math.PI / 2, -0.3, -0.3]} />
                    <Laser textures={props.textures} scale={props.scale * 0.16} position={[props.position[0] - 0.35, props.position[1] + 0.08, props.position[2] - 0.2]} rotation={[0, 0, -1.9]} />

                    <F3Xid onClick={() => { props.setInFocus("faction-3"); cameraOnDao(); }} scale={props.scale * 0.17} position={[props.position[0] - 0.60, props.position[1] - 0.34, props.position[2]]} rotation={[Math.PI / 2, 0.3, -0.8]} />
                    <Laser textures={props.textures} scale={props.scale * 0.16} position={[props.position[0] - 0.30, props.position[1] - 0.26, props.position[2] - 0.3]} rotation={[0, 0, -0.9]} />

                    <F6Xid onClick={() => { props.setInFocus("faction-6"); cameraOnDao(); }} scale={props.scale * 0.17} position={[props.position[0] + 0.28, props.position[1] - 0.35, props.position[2]]} rotation={[Math.PI / -2, 0.4, 0]} />
                    <Laser textures={props.textures} scale={props.scale * 0.16} position={[props.position[0] + 0.34, props.position[1] - 0.25, props.position[2] - 0.5]} rotation={[0, 0, 1]} />

                    <F4Xid onClick={() => { props.setInFocus("faction-4"); cameraOnDao(); }} scale={props.scale * 0.17} position={[props.position[0] - 0.18, props.position[1] - 0.50, props.position[2]]} rotation={[Math.PI / 2.4, 0, 0]} />
                    <Laser textures={props.textures} scale={props.scale * 0.16} position={[props.position[0] - 0.03, props.position[1] - 0.35, props.position[2]]} rotation={[0, 0, 0]} />

                    <F5Xid onClick={() => { props.setInFocus("faction-5"); cameraOnDao(); }} scale={props.scale * 0.17} position={[props.position[0] - 0.17, props.position[1] + 0.46, props.position[2] + 0.3]} rotation={[Math.PI / -2.4, 0, 0]} />
                    <Laser textures={props.textures} scale={props.scale * 0.16} position={[props.position[0] - 0.01, props.position[1] + 0.35, props.position[2]]} rotation={[0, 0, 0]} />

                    <group
                        onPointerEnter={() => { props.setIsActive("DAO") }}
                        onPointerLeave={() => { props.setIsActive(null) }}
                        onClick={() => {
                            moveCamera();
                        }}>
                        <OBJDAO setInFocus={props.setInFocus} controlsRef={props.controlsRef} setIsActive={props.setIsActive} position={props.position} scale={props.scale * 0.049} />
                    </group>


                </>) : (<>

                    {/* ora 1 */}
                    <F1Xid onClick={() => { props.setInFocus("faction-1"); cameraOnDao(); }} scale={props.scale * 0.08} position={[props.position[0] + 1.35, props.position[1] + 0.5, props.position[2]]} rotation={[Math.PI / 2, 0.3, 0.3]} />
                    <Laser textures={props.textures} scale={props.scale * 0.08} position={[props.position[0] + 1, props.position[1] + 0.35, props.position[2] - 0.1]} rotation={[0, 0, -1.2]} />

                    <F2Xid onClick={() => { props.setInFocus("faction-2"); cameraOnDao(); }} scale={props.scale * 0.08} position={[props.position[0] - 1.35, props.position[1] + 0.5, props.position[2]]} rotation={[Math.PI / 2, -0.3, -0.3]} />
                    <Laser textures={props.textures} scale={props.scale * 0.08} position={[props.position[0] - 1, props.position[1] + 0.35, props.position[2] - 0.1]} rotation={[0, 0, -1.9]} />

                    <F3Xid onClick={() => { props.setInFocus("faction-3"); cameraOnDao(); }} scale={props.scale * 0.08} position={[props.position[0] - 1.25, props.position[1] - 0.8, props.position[2]]} rotation={[Math.PI / 2, 0.3, -0.8]} />
                    <Laser textures={props.textures} scale={props.scale * 0.08} position={[props.position[0] - 0.9, props.position[1] - 0.65, props.position[2] - 0.3]} rotation={[0, 0, -0.9]} />

                    <F6Xid onClick={() => { props.setInFocus("faction-6"); cameraOnDao(); }} scale={props.scale * 0.08} position={[props.position[0] + 1.25, props.position[1] - 0.8, props.position[2]]} rotation={[Math.PI / -2, 0.4, 0]} />
                    <Laser textures={props.textures} scale={props.scale * 0.08} position={[props.position[0] + 0.9, props.position[1] - 0.65, props.position[2] - 0.3]} rotation={[0, 0, 1.2]} />

                    <F4Xid onClick={() => { props.setInFocus("faction-4"); cameraOnDao(); }} scale={props.scale * 0.08} position={[props.position[0] - 0, props.position[1] - 1.5, props.position[2]]} rotation={[Math.PI / 2.4, 0, 0]} />
                    <Laser textures={props.textures} scale={props.scale * 0.08} position={[props.position[0], props.position[1] - 1.1, props.position[2] - 1]} rotation={[0, 0, 0]} />

                    <F5Xid onClick={() => { props.setInFocus("faction-5"); cameraOnDao(); }} scale={props.scale * 0.08} position={[props.position[0] - 0, props.position[1] + 1.5, props.position[2]]} rotation={[Math.PI / -2.4, 0, 0]} />
                    <Laser textures={props.textures} scale={props.scale * 0.08} position={[props.position[0], props.position[1] + 1.1, props.position[2] - 1]} rotation={[0, 0, 0]} />

                    <group
                        onPointerEnter={() => { props.setIsActive("DAO") }}
                        onPointerLeave={() => { props.setIsActive(null) }}
                        onClick={() => {
                            moveCamera();
                        }}>
                        <OBJDAO setInFocus={props.setInFocus} controlsRef={props.controlsRef} setIsActive={props.setIsActive} position={props.position} scale={props.scale * 0.049} />
                    </group>


                </>)
            }



        </>
    );
}


