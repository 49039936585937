import React, { memo, useMemo, useRef } from 'react';
import { MeshRefractionMaterial, useGLTF } from '@react-three/drei';
import { Color, MeshPhysicalMaterial, MeshStandardMaterial, Vector3 } from 'three';
import { useSpring, animated, a } from '@react-spring/three';
import { useFrame, useLoader } from '@react-three/fiber';
import { RGBELoader } from 'three-stdlib';

export const Edge = memo((props) => {
    const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/edge/edgeFinal.glb`);

    const material1 = new MeshStandardMaterial({
        map: materials['1'].map,
        metalness: 0.6,
        roughness: 0.8
    });
    const material2 = new MeshStandardMaterial({
        map: materials['2'].map,
        metalness: 0.6,
        roughness: 0.8,
    });
    const glowCyan = new MeshStandardMaterial({
        opacity: 1,
        transparent: true,
        emissive: "cyan",
        emissiveIntensity: 3,
        toneMapped: false
    });

    const glowRed = new MeshStandardMaterial({
        opacity: 1,
        transparent: true,
        emissive: "red",
        emissiveIntensity: 3,
        toneMapped: false
    });


    const moveCamera = () => {
        props.setInFocus("edge");
        //props.setAxisOpacity(0);
        if (props.windowWidth > 1060)
            props.controlsRef.current?.setLookAt(-5.4, 1, 8, -5.4, 1, 1, true);
    }

    const moveCameraInside = () => {
        document.title = "ProjectX • Edge";
        props.setInFocus("edge");
        //props.setAxisOpacity(0);
        if (props.windowWidth > 1060) {
            props.controlsRef.current?.setLookAt(-4.4, 1.6, 8, -4.4, 1.6, 1, true);
            props.controlsRef.current?.setPosition(0, 4, 10, true);
        }
    }

    const beaconIntensity = useRef();

    const green = useMemo(() => new Color(0.26, 1, 1), []); // rgb(26, 255, 255)
    const red = useMemo(() => new Color(1, 0, 0), []);

    useFrame((state, delta) => {
        // Calculate the elapsed time
        const time = state.clock.getElapsedTime();
        group.current.rotation.y += props.animationEdge ? delta * 0.2 : delta * 0.05;

        // Calculate oscillation between 0 and 1
        const oscillation = (Math.sin(time) + 1) / 2;

        // Update intensity
        if (beaconIntensity.current) {
            beaconIntensity.current.intensity = props.animationEdge ? oscillation * 2 : oscillation;

            // Update color based on props.animationEdge
            beaconIntensity.current.color = props.animationEdge ? red : green;
        }

    });


    const group = useRef();

    return (<>
        {/* // DRACO COMPRESSION */}
        {/* // <group {...props} dispose={null} rotation={[0.3, 0, 0.1]}>
        //     <mesh name="Edge-1" geometry={nodes['Edge-1'].geometry} material={material1} position={[0.007, 0.014, -0.024]} scale={0.1} />
        //     <mesh name="Edge-2" geometry={nodes['Edge-2'].geometry} material={material2} position={[0.007, 0.014, -0.024]} scale={0.1} />
        //     <mesh name="glowRosu" geometry={nodes.glowRosu.geometry} material={glowRed} position={[-0.007, -0.012, 0.017]} scale={0.1} >
        //     </mesh>
        //     <mesh name="glowVerde" geometry={nodes.glowVerde.geometry} material={glowCyan} position={[-0.007, -0.012, 0.017]} scale={0.1} >
        //     </mesh>
        //     <mesh name="Rock" geometry={nodes.Rock.geometry} material={material2} position={[0.007, 0.01, -0.01]} scale={0.1} />
        // </group> */}


        {/* LIGHT FOR EDGE */}
        <pointLight distance={props.windowWidth > 1060 ? 1 : 2} position={props.windowWidth > 1060 ? [-4, 1.5, -1.8] : [0, 0, 0]} intensity={1.5} color={'white'} />
        <pointLight distance={props.windowWidth > 1060 ? 1 : 2} position={props.windowWidth > 1060 ? [-3.5, 0.7, -1.6] : [0, 0.3, 0.6]} intensity={1.3} color={'white'} />
        <pointLight ref={beaconIntensity} distance={props.windowWidth > 1060 ? 1 : 10} position={props.windowWidth > 1060 ? [-4.4, 1.2, -1.6] : [1, 0.5, 2]} power={1} color={'cyan'} />


        {/* // NORMAL COMPRESSION */}
        <group {...props}
            ref={group}
            rotation={[0.4, 0, 0.1]}
            onPointerEnter={() => { props.setIsActive("Edge") }}
            onPointerLeave={() => { props.setIsActive(null) }}
            onClick={() => {
                moveCamera()
            }}
            // rotation-x={0.3}
            dispose={null}>
            <mesh
                geometry={nodes.Rock.geometry}
                position={[0.074, 0.097, -0.099]}
            >
                <meshStandardMaterial map={materials['2'].map} color={props.animationEdge ? "red" : "cyan"} />
                {/* <MeshRefractionMaterial envMap={texture} toneMapped={false} /> */}
            </mesh>
            <group position={[0.069, 0.138, -0.238]}>
                <mesh


                    geometry={nodes['Edge-2'].geometry}
                    material={material2}
                />
                <mesh


                    geometry={nodes['Edge-1'].geometry}
                    material={material1}
                />
            </group>
            <mesh
                geometry={nodes.glowVerde.geometry}
                material={glowCyan}
                position={[-0.072, -0.118, 0.169]}
            />
            <mesh
                geometry={nodes.glowRosu.geometry}
                material={glowRed}
                position={[-0.072, -0.118, 0.169]}
            />
        </group>

        {/* // <group {...props}
        //     onPointerEnter={() => { props.setIsActive("Edge") }}
        //     onPointerLeave={() => { props.setIsActive(null) }}
        //     scale={props.scale}   rotation={props.rotation} dispose={null}>
        //     <animated.group
        //         ref={edgeRef}
        //         
        //         
        //         position={[0, 0, 0]}
        //         rotation-x={Math.PI / 2}
        //         rotation-z={rotationZ}
        //     >
        //         <mesh   geometry={nodes.Edge_1.geometry} material={material1} />
        //         <mesh   geometry={nodes.Edge_2.geometry} material={material1} />
        //     </animated.group>
        //     <animated.mesh
        //         position={[0, 0, 0]}
        //         
        //         
        //         geometry={nodes.Rock.geometry}
        //         material={material2Ref.current}
        //         rotation={[Math.PI / 2, 0, 0]}
        //         emissiveIntensity={emissiveIntensity}
        //     />
        // </group> */}
    </>);
});

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/edge/edgeFinal.glb`);
