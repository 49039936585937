import { useRef, useMemo, useCallback } from 'react';
import { MeshStandardMaterial } from 'three';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

export default function Working(props) {
    const { nodes } = useGLTF(`${process.env.PUBLIC_URL}/v2/xid/ciocansinicovala.gltf`);

    const allRef = useRef();
    const hammerRef = useRef();
    const timeRef = useRef(0);  // Persisting the time variable across renders

    const material = useMemo(() => {
        return new MeshStandardMaterial({
            color: 'cyan',
            opacity: 0.2,
            emissive: 'cyan',
            emissiveIntensity: 1,
            transparent: true,
            wireframe: true
        });
    }, []);

    const updateRotation = useCallback((delta) => {
        allRef.current.rotation.y -= delta * 0.5;
        timeRef.current += delta;
        const rotationX = -0.6 + Math.sin(timeRef.current * Math.PI / 1.4) * (1.55 - 0.6);
        hammerRef.current.rotation.x = rotationX;
    }, []);

    useFrame((state, delta) => {
        updateRotation(delta);
    });

    return (
        <group ref={allRef} {...props} position={props.position} dispose={null}>
            <mesh
                geometry={nodes.Anvil.geometry}
                material={material}
                position={[0, 0, 0]}
            />
            <mesh
                ref={hammerRef}
                geometry={nodes.Hammer.geometry}
                material={material}
                position={[0, 2, 1.5]}
                rotation={[-Math.PI / 2, 0, 0]}
            />
        </group>
    );
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/xid/ciocansinicovala.gltf`);
