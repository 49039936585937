const vertexShader = `
uniform float u_time;

varying vec2 vUv;

void main() {
    vUv=uv;
  vec4 modelPosition = modelMatrix * vec4(position, 1.0);
  // modelPosition.z += sin(modelPosition.y * 1.0 + u_time * 2.0) * 0.1;
  modelPosition.x += sin(modelPosition.y * 20.0 + u_time * 2.0) * 0.01;

  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectedPosition = projectionMatrix * viewPosition;

  gl_Position = projectedPosition;
}
`;

export default vertexShader;
