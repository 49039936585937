import './App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Plane, PresentationControls, useTexture } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import logo from './logo.png'
import { useEffect, useState } from 'react';

function setCookie(name, value, days) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
}

function getCookie(name) {
    const cookie = document.cookie.split('; ').find(v => v.startsWith(name + '='));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
}


export default function LoadingScreen(props) {

    const [cookieState, setCookieState] = useState(true);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const showAgain = getCookie('showAgain');
        if (showAgain === null)
            setCookie("showAgain", "true", 365);

        // console.log("showAgain", showAgain)

        if (props.progress > 99.9) { // if it's loaded, add the animation
            setTimeout(() => {
                const loadingScreenElement = document.getElementById('loadingscreen');
                const logoLoadingScreen = document.getElementById('logoLoadingScreen');
                const textLoadingScreen = document.getElementById('textLoadingScreen');
                if (loadingScreenElement && logoLoadingScreen && textLoadingScreen) {
                    loadingScreenElement.classList.add('loaded');
                    logoLoadingScreen.classList.add('loaded');
                    textLoadingScreen.classList.add('loaded');
                    setLoaded(true);
                    if (showAgain === 'true' || showAgain === true || showAgain === null)
                        setCookieState(true);
                    else
                        setCookieState(false);


                }
            }, 2500)
        }
    }, [props.progress])


    const showAgain = (value) => {
        setCookie("showAgain", value, 365);
        setCookieState(false);
    }

    return (
        <>
            {
                props.windowWidth > 1060 ? (<>
                    <div id='loadingscreen' className='loadingscreen' style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <img id='logoLoadingScreen' className='logoLoadingScreen' src={logo} />
                        <div id='textLoadingScreen' className='textLoadingScreen' style={{ fontSize: 18 }}>{(props.progress).toFixed(0)}/100 DNA loaded into procedure...</div>
                    </div>
                    {(cookieState === true && loaded === true) ? (<>
                        <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <div style={{ position: 'fixed', width: '100%', height: '100%', backdropFilter: 'blur(5px)' }} />
                            <div style={{ position: 'fixed', width: '40%', backgroundColor: 'rgba(0,0,0,0.8)', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)', gap: '2rem', padding: '2rem', borderRadius: 40, zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", fontSize: 30, textAlign: 'center' }}>Welcome to the X-Map!</div>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", fontSize: 24, textAlign: 'center' }}>Embark on a journey through the interactive, real-time, and fully immersive ecosystem of Project X. Dive in and explore all the products operating for the DAO. Interact with them and stay alert for the seamless events unfolding in our galaxy. There's always something new to discover!</div>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", fontSize: 30, textAlign: 'center', gap: '5rem' }}>
                                    <div onClick={() => { showAgain(false) }} style={{ position: 'relative', width: '20%', fontSize: 20, whiteSpace: 'nowrap' }}>Never show again.</div>
                                    <div onClick={() => { showAgain(true) }} className='ok-btn' style={{ position: 'relative', fontSize: 20, color: 'black' }}>Got it.</div>
                                </div>
                            </div>
                        </div>
                    </>) : null}
                </>) :
                    (<>
                        <div id='loadingscreen' className='loadingscreen' style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img id='logoLoadingScreen' className='logoLoadingScreen' src={logo} style={{ width: '50%' }} />
                            <div id='textLoadingScreen' className='textLoadingScreen' style={{ fontSize: 16, textAlign: 'center' }}>{(props.progress).toFixed(0)}/100 <br /> DNA loaded into procedure...</div>
                        </div>
                        {(cookieState === true && loaded === true) ? (<>
                            <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ position: 'fixed', width: '100%', height: '100%', backdropFilter: 'blur(5px)' }} />
                                <div style={{ position: 'fixed', width: '90%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)', gap: '2rem', padding: '2rem', borderRadius: 40, zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", fontSize: 30, textAlign: 'center' }}>Welcome to the X-Map!</div>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'start',
                                        color: "white",
                                        fontSize: 20,
                                        textAlign: 'center',
                                        overflow: 'auto',
                                        maxHeight: '13rem',
                                        paddingBottom: '2rem',
                                        boxSizing: 'border-box'
                                    }}>
                                        Embark on a journey through the interactive, real-time, and fully immersive ecosystem of Project X. Dive in and explore all the products operating for the DAO. Interact with them and stay alert for the seamless events unfolding in our galaxy. There's always something new to discover!

                                    </div>

                                    <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", fontSize: 30, textAlign: 'center', gap: '2rem', flexDirection: 'column' }}>
                                        <div onClick={() => { showAgain(true) }} className='ok-btn' style={{ position: 'relative', fontSize: 20, color: 'black' }}>Got it</div>
                                        <div onClick={() => { showAgain(false) }} style={{ position: 'relative', width: '100%', fontSize: 16, whiteSpace: 'nowrap' }}>Never show again</div>
                                    </div>
                                </div>
                            </div>
                        </>) : null}
                    </>)
            }
        </>
    );
}


