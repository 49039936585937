import React, { useEffect, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

const SoundLine = (props) => {

    const [soundStatus, setSoundStatus] = useState(false);

    const alterSound = () => {
        if (props.sound && props.sound.current) {
            // console.log(props.sound.current);
            if (props.sound.current.isPlaying) {
                setSoundStatus(true);
                props.sound.current.pause();
            } else {
                setSoundStatus(false);
                props.sound.current.play();
            }
        }
    };

    return (
        <div onClick={() => { alterSound(); }} style={{ position: 'fixed', top: '2rem', right: '8rem', zIndex: 1, paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', borderRadius: 20, backgroundColor: 'white' }}>
            <div>
                {soundStatus ? (<><FontAwesomeIcon icon={faVolumeMute} /></>) : (<><FontAwesomeIcon icon={faVolumeHigh} /></>)}
            </div>
        </div>
    );
};

export default SoundLine;
