import logo from './logo.svg';
import './App.css';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import { useSpring, a } from '@react-spring/three';

export default function Lights(props) {

  return (
    <>
      {/* <pointLight position={[0,2.2,0.3]} intensity={5} color={'white'}/> */}
      {/* <ambientLight intensity={3.5} color={'white'}/> */}

      {/* LIGHT FOR GOLD */}
      <pointLight ref={props.lightDaoRef} distance={1} position={[-2.6, -1.3, 1]} intensity={1.5} color={'white'} />
      <pointLight ref={props.lightDaoRef} distance={1.1} position={[-2.5, -1.5, 2]} intensity={1.2} color={'white'} />

      {/* LIGHT FOR SATURN (PINK) */}
      <pointLight ref={props.lightDaoRef} distance={0} position={[-1.8, 0.1, -0.5]} intensity={0.7} color={'white'} />

      {/* LIGHT FOR XID */}
      {/* <pointLight ref={props.lightDaoRef} distance={5} position={[-1.3, 1.7, -3.7]} intensity={3} color={'white'}   /> */}

      <pointLight ref={props.lightDaoRef} distance={4} position={[-1.4, 1.8, -3.5]} intensity={0.4} color={'white'} />

      {/* LIGHT FOR EARTH */}
      <pointLight distance={0} position={[0, 0, 0.7]} intensity={10} power={100} color={'white'} />

      {/* LIGHT FOR SPACEPORT */}
      <pointLight distance={1} position={[2.65, -1, 1]} intensity={5} power={60} color={'#19ffff'} />
      <pointLight distance={1} position={[1.5, -1, 1]} intensity={1} power={60} color={'#ffffff'} />
      <pointLight distance={1} position={[2.35, -1, 1]} intensity={1} power={2} color={'#ffffff'} />
      <pointLight distance={1} position={[2.35, -1, 1.4]} intensity={1} power={3} color={'#ffffff'} />
      {/* <pointLight distance={2} position={[2.7, -1, 1.8]} intensity={1} power={3} color={'#ffffff'}   /> */}

      {/* LIGHT FOR THE X OF DAO*/}
      <pointLight position={[2.5, 1.2, 2]} distance={3} intensity={2} color={'white'} />
      <ambientLight position={[0, 0, 1]} distance={1} intensity={1.1} color={'white'} />
      {/* <pointLight   ref={props.lightDaoRef} position={[-3.5,0.8,3]} intensity={20} color={'white'}/>  */}


      {/* LIGHT FOR UNKNOWN*/}
      <pointLight position={[1.25, 1.7, -3.3]} distance={1} intensity={2.3} color={'white'} />

      {/* LIGHT FOR THE CIRCLE*/}
      <pointLight position={[3.8, 0.4, 2.6]} distance={1} intensity={1} color={'white'} />
      <pointLight position={[4.43, 0.27, 2.6]} distance={0.5} intensity={0.6} color={'white'} />
    </>
  );
}
