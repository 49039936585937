import logo from './logo.svg';
import './App.css';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { CameraControls, Environment, EnvironmentCube, Html, Image, Loader, MeshPortalMaterial, OrbitControls, PerspectiveCamera, Plane, PresentationControls, Scroll, ScrollControls, SoftShadows, Sphere, StatsGl, useProgress, useTexture, useVideoTexture } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { AudioLoader, BackSide, Box3, DoubleSide, MOUSE, MeshStandardMaterial, SphereGeometry, TextureLoader, Vector3, PlaneGeometry } from 'three';
import Background from './Background';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import Earth from './Objects/Earth/Earth';
import DAO from './Objects/DAO/DAO';
import CloudsXID from './Objects/XID/CloudsXID';
import CloudsSaturn from './Objects/XID/CloudsXID';
import Smoke from './Objects/Extras/Smoke';
import XID from './Objects/XID/XID';
import Saturn from './Objects/Saturn/Saturn';
import { useControls } from 'leva';
import Gold from './Objects/Gold/Gold';
import Factions from './Objects/Factions/FactionManager';
import FactionManager from './Objects/Factions/FactionManager';
import SunLines from './Objects/Extras/SunLines';
import Sun from './Objects/Extras/Sun'
import Spaceport from './Objects/Spaceport/Spaceport';
import Road from './Objects/Extras/Train/Road';
import Train from './Objects/Extras/Train/Train';
import RoadLine from './Objects/Extras/Train/Rail';
import Rail from './Objects/Extras/Train/Rail';
import Ring from './Objects/Ring/Ring';
import AnimatedSpaceport from './Objects/Spaceport/AnimatedSpaceport';
import AnimatedRing from './Objects/Ring/AnimatedRing';
import { useDrag } from "@use-gesture/react";
import gsap from 'gsap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Edge } from './Objects/EDGE/Edge';
import Unknown from './Objects/Unknown/Unknown';
import { proxy, useSnapshot } from "valtio"
import * as THREE from 'three';
import Sound from './Objects/Sound';
import BlackHole from './Objects/BlackHole';
import { Perf, PerfHeadless } from 'r3f-perf'

const MemoizedSun = React.memo(Sun);
const MemoizedEdge = React.memo(Edge);
const MemoizedEarth = React.memo(Earth);
const MemoizedDAO = React.memo(DAO);
const MemoizedGold = React.memo(Gold);
const MemoizedSpaceport = React.memo(Spaceport);
const MemoizedSaturn = React.memo(Saturn);
const MemoizedXID = React.memo(XID);
const MemoizedUnknown = React.memo(Unknown);
const MemoizedRing = React.memo(AnimatedRing);
const MemoizedBlackHole = React.memo(BlackHole);

export default function Scene(props) {

    const material = new MeshStandardMaterial({ color: '#6e6e6e', roughness: 0.6 })
    const sphere = useMemo(() => new SphereGeometry(1, 28, 28), [])
    const plane = useMemo(() => new PlaneGeometry(3, 3), [])

    const cameraRef = useRef();

    const setBoundary = () => {
        if (props.controlsRef) {
            const zoomUpperLimit = 40; // upper limit of zoom range, smaller boundary
            const zoomLowerLimit = 20; // lower limit of zoom range, larger boundary

            // normalized the zoom value to a 0-1 range where 0 corresponds to zoom 30 and 1 to zoom 20
            const normalizedZoom = (props.controlsRef.current.distance - zoomUpperLimit) / (zoomLowerLimit - zoomUpperLimit);

            // defined the boundary box sizes for zoom 30 (min size)
            const minSizeAtMaxZoom = new Vector3(0, 0, 0);
            const maxSizeAtMaxZoom = new Vector3(0, 0, 0);

            // defined the boundary box sizes for zoom 20 (max size)
            const minSizeAtMinZoom = new Vector3(-2, -2, -0.5);
            const maxSizeAtMinZoom = new Vector3(2, 2, 0.5);

            // linearly interpolate between the sizes based on the normalized zoom
            const interpolatedMin = minSizeAtMaxZoom.clone().lerp(minSizeAtMinZoom, normalizedZoom);
            const interpolatedMax = maxSizeAtMaxZoom.clone().lerp(maxSizeAtMinZoom, normalizedZoom);

            const boundaryBox = new Box3(interpolatedMin, interpolatedMax);
            props.controlsRef.current.setBoundary(boundaryBox);
        }
        props.setInFocus(null);
        document.title = "ProjectX • Home";
    }

    const textures = useLoader(TextureLoader, [
        `${process.env.PUBLIC_URL}/v2/background.webp`,
        `${process.env.PUBLIC_URL}/v2/background/backgroundGALAXY.webp`,
        `${process.env.PUBLIC_URL}/v2/background/backgroundGALAXY2lowRez.webp`,
        `${process.env.PUBLIC_URL}/v2/background/backgroundGALAXY3lowRez.webp`,
        `${process.env.PUBLIC_URL}/v2/environment/sun.webp`,
        `${process.env.PUBLIC_URL}/border2.webp`,
        `${process.env.PUBLIC_URL}/v2/dao/webp/textureDao.webp`,
        `${process.env.PUBLIC_URL}/v2/factions/webp/laser_new.webp`,
        `${process.env.PUBLIC_URL}/v2/earth/webp/cloudsEarth.webp`,
        `${process.env.PUBLIC_URL}/v2/earth/webp/pamantPROJX.webp`,
        `${process.env.PUBLIC_URL}/v2/earth/webp/pamantPROJXcanare.webp`,
        `${process.env.PUBLIC_URL}/factions/webp/faction1.webp`,
        `${process.env.PUBLIC_URL}/factions/webp/faction2.webp`,
        `${process.env.PUBLIC_URL}/factions/webp/faction3.webp`,
        `${process.env.PUBLIC_URL}/factions/webp/faction4.webp`,
        `${process.env.PUBLIC_URL}/factions/webp/faction5.webp`,
        `${process.env.PUBLIC_URL}/v2/gold/webp/textureGold.webp`,
        `${process.env.PUBLIC_URL}/ring/webp/ring.webp`,
        `${process.env.PUBLIC_URL}/ring/webp/ringSpaceship.webp`,
        `${process.env.PUBLIC_URL}/v2/saturn/webp/cloudsSaturn.webp`,
        `${process.env.PUBLIC_URL}/saturn/webp/linesSaturn.webp`,
        `${process.env.PUBLIC_URL}/v2/saturn/webp/textureSaturn.webp`,
        `${process.env.PUBLIC_URL}/v2/saturn/webp/sattelites.webp`,
        `${process.env.PUBLIC_URL}/v2/saturn/webp/sattelitesDOWN.webp`,
        `${process.env.PUBLIC_URL}/v2/saturn/webp/ringSaturn.webp`,
        `${process.env.PUBLIC_URL}/v2/xid/pannelsXid.webp`,
        `${process.env.PUBLIC_URL}/v2/xid/textureXid.webp`,
        `${process.env.PUBLIC_URL}/v2/unknown/textureUnknown.webp`,
        `${process.env.PUBLIC_URL}/logo.webp`
    ]);

    const memoizedTextures = useMemo(() => {
        const [
            envMap, background, background2, background3, sun, border,
            textureDao, laserDao,
            cloudsEarth, textureEarth, textureEarthCanare, faction1, faction2, faction3, faction4, faction5,
            textureGold,
            ring, ringSpaceship,
            cloudsSaturn, linesSaturn, textureSaturn, sattelites, sattelitesDOWN, ringSaturn,
            panelsXid, textureXid, textureUnknown, logo
        ] = textures;

        return {
            envMap, background, background2, background3, sun, border,
            textureDao, laserDao,
            cloudsEarth, textureEarth, textureEarthCanare, faction1, faction2, faction3, faction4, faction5,
            textureGold,
            ring, ringSpaceship,
            cloudsSaturn, linesSaturn, textureSaturn, sattelites, sattelitesDOWN, ringSaturn,
            panelsXid, textureXid, textureUnknown, logo
        };
    }, [textures]);

    return (
        <>

            <Background axisOpacity={props.axisOpacity} textures={memoizedTextures} windowWidth={props.windowWidth} />

            {
                props.windowWidth > 1060 ? (<>
                    <Lights lightDaoRef={props.lightDaoRef} />
                    <PerspectiveCamera ref={cameraRef} makeDefault={true} position={[0, 0, 30]} fov={11} zoom={1} />
                    <CameraControls
                        mouseButtons={{ left: 2, right: 2, wheel: 8, middle: 8 }}
                        touches={{ one: 2, two: 8 }}
                        onStart={setBoundary}
                        ref={props.controlsRef}
                        enabled={true}
                        enableZoom={true}
                        minDistance={5}
                        maxDistance={30}
                        enablePan={false}
                        enableRotate={false}
                        minPolarAngle={1.5}
                        maxPolarAngle={1.5}
                        minAzimuthAngle={-0.1}
                        maxAzimuthAngle={0}
                        boundaryEnclosesCamera={false}
                        smoothTime={0.2}
                        panSpeed={1}
                        rotateSpeed={1}
                        zoomSpeed={0.2}
                    />
                    <Sound sound={props.sound} everythingLoaded={props.everythingLoaded} setEverythingLoaded={props.setEverythingLoaded} link={`${process.env.PUBLIC_URL}/halo_ambient.mp3`} />
                    <MemoizedBlackHole textures={memoizedTextures} setIsActive={props.setIsActive} />
                    <MemoizedEdge position={[-4.4, 1, -1.8]} scale={0.25} setInFocus={props.setInFocus} setIsActive={props.setIsActive} animationEdge={props.animationEdge} setAxisOpacity={props.setAxisOpacity} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedSun position={[0, 0, 1]} scale={0.65} plane={plane} setIsActive={props.setIsActive} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedEarth position={[-1.3, -0.5, 1.2]} scale={0.3} setIsActive={props.setIsActive} animationAlertEarth={props.animationAlertEarth} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} sphere={sphere} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedDAO position={[3.15, 1.2, 1.2]} scale={0.3} setIsActive={props.setIsActive} setAxisOpacity={props.setAxisOpacity} totalDelta={props.totalDelta} setInFocus={props.setInFocus} delta={props.delta} planetNo={props.planetNo} sphere={sphere} textures={memoizedTextures} material={material} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedGold position={[-3, -1.8, 1.2]} scale={0.3} animationGold={props.animationGold} setAnimationGold={props.setAnimationGold} setIsActive={props.setIsActive} setAxisOpacity={props.setAxisOpacity} windowWidth={props.windowWidth} inFocus={props.inFocus} setInFocus={props.setInFocus} controlsRef={props.controlsRef} sphere={sphere} textures={memoizedTextures} material={material} trainRef={props.trainRef} />
                    <MemoizedSpaceport position={[2.65, -1.25, 1]} scale={0.02} setIsActive={props.setIsActive} textures={memoizedTextures} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedSaturn position={[-2.5, 0.25, -0.5]} scale={0.35} setAnimationSaturn={props.setAnimationSaturn} animationSaturn={props.animationSaturn} setIsActive={props.setIsActive} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} sphere={sphere} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedXID setIsActive={props.setIsActive} setInFocus={props.setInFocus} position={[-1.8, 2.2, -4]} sphere={sphere} textures={memoizedTextures} scale={0.35} setAxisOpacity={props.setAxisOpacity} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedUnknown animationUnknown={props.animationUnknown} setAnimationUnknown={props.setAnimationUnknown} setIsActive={props.setIsActive} setInFocus={props.setInFocus} position={[1.8, 2.1, -4]} sphere={sphere} textures={memoizedTextures} scale={0.3} setAxisOpacity={props.setAxisOpacity} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                    <MemoizedRing position={[4.3, 0.15, 2.6]} scale={0.055} rotation={[0.4, 0, 0]} setAnimationRing={props.setAnimationRing} animationRing={props.animationRing} setIsActive={props.setIsActive} setInFocus={props.setInFocus} setAxisOpacity={props.setAxisOpacity} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                </>) : (<>
                    <PerspectiveCamera makeDefault position={[0, 0, 30]} fov={11} zoom={1} />
                    <ScrollControls pages={7} damping={0.05} distance={0.7}>
                        <Scroll>
                            <ambientLight intensity={0.8} color={'white'} />

                            <group scale={1}>
                                <pointLight distance={20} position={[0, -0.8, 1.5]} intensity={2} color={'white'} />
                                <pointLight distance={2} position={[0, 1.2, 1]} intensity={1} color={'white'} />
                                <MemoizedEarth position={[0, 0, 0]} scale={1} setIsActive={props.setIsActive} animationAlertEarth={props.animationAlertEarth} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} sphere={sphere} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>

                            <group scale={0.8} position={[0, -4.5, 0]}>
                                <pointLight position={[0, 0, 2]} intensity={4} distance={2} />
                                <MemoizedDAO position={[0, 0, 0]} scale={1} setIsActive={props.setIsActive} setAxisOpacity={props.setAxisOpacity} totalDelta={props.totalDelta} setInFocus={props.setInFocus} delta={props.delta} planetNo={props.planetNo} sphere={sphere} textures={memoizedTextures} material={material} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>

                            <group scale={0.9} position={[0, -9, 0]}>
                                <pointLight distance={4} position={[-1.5, 1, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[1.5, 2, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[0, -1, 2]} intensity={5} color={'white'} />
                                <MemoizedSaturn position={[0, 0, 0]} setAnimationSaturn={props.setAnimationSaturn} animationSaturn={props.animationSaturn} setIsActive={props.setIsActive} scale={1} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} sphere={sphere} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>

                            <group scale={0.8} position={[0, -12.5, 0]}>
                                <MemoizedEdge position={[0, 0, 0]} scale={0.5} setAnimationEdge={props.setAnimationEdge} animationEdge={props.animationEdge} setIsActive={props.setIsActive} setInFocus={props.setInFocus} setAxisOpacity={props.setAxisOpacity} rotation={[Math.PI / 2 - 1.1, 0, -0.27]} textures={props.textures} windowWidth={props.windowWidth} />
                            </group>

                            <group scale={1} position={[0, -16, 0]}>
                                <pointLight distance={4} position={[0, -1.3, 1]} intensity={2} color={'white'} />
                                <pointLight distance={4} position={[0, 2, 1]} intensity={4} color={'white'} />
                                <pointLight distance={3} position={[0, 0, -1]} intensity={40} color={'white'} />
                                <MemoizedGold position={[0, 0, 0]} scale={1} animationGold={props.animationGold} setAnimationGold={props.setAnimationGold} setIsActive={props.setIsActive} setAxisOpacity={props.setAxisOpacity} windowWidth={props.windowWidth} inFocus={props.inFocus} setInFocus={props.setInFocus} controlsRef={props.controlsRef} sphere={sphere} textures={memoizedTextures} material={material} trainRef={props.trainRef} />
                            </group>

                            <group scale={0.15} position={[0, -20, 0]}>
                                <pointLight distance={4} position={[0, 0, 0]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[10, 2, 8]} intensity={3} color={'cyan'} />
                                <pointLight distance={4} position={[-10, 2, 8]} intensity={3} color={'cyan'} />
                                <MemoizedRing position={[0, 0, 0]} scale={1} rotation={[0.4, 0, 0]} setAnimationRing={props.setAnimationRing} animationRing={props.animationRing} setIsActive={props.setIsActive} setInFocus={props.setInFocus} setAxisOpacity={props.setAxisOpacity} textures={memoizedTextures} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>

                            <group scale={0.9} position={[0, -24, 0]}>
                                <pointLight distance={4} position={[-1.5, 1, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[1.5, 2, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[0, -1, 1]} intensity={3} color={'white'} />
                                <pointLight distance={2} position={[0, 0, -1]} intensity={30} color={'cyan'} />
                                <MemoizedSpaceport position={[0, 0, 0]} setIsActive={props.setIsActive} scale={0.045} textures={memoizedTextures} setAxisOpacity={props.setAxisOpacity} setInFocus={props.setInFocus} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>

                            <group scale={0.9} position={[0, -28, 0]}>
                                <pointLight distance={4} position={[-1.5, 1, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[1.5, 2, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[0, -1, 2]} intensity={5} color={'white'} />
                                <MemoizedXID position={[0, 0, 0]} setIsActive={props.setIsActive} setInFocus={props.setInFocus} sphere={sphere} textures={memoizedTextures} scale={1} setAxisOpacity={props.setAxisOpacity} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>


                            <group scale={0.8} position={[0, -32, 0]}>
                                <pointLight distance={4} position={[-1.5, 1, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[1.5, 2, 0.5]} intensity={5} color={'white'} />
                                <pointLight distance={4} position={[0, -1, 2]} intensity={5} color={'white'} />
                                <MemoizedUnknown position={[0, 0, 0]} animationUnknown={props.animationUnknown} setAnimationUnknown={props.setAnimationUnknown} setIsActive={props.setIsActive} setInFocus={props.setInFocus} sphere={sphere} textures={memoizedTextures} scale={1} setAxisOpacity={props.setAxisOpacity} controlsRef={props.controlsRef} windowWidth={props.windowWidth} />
                            </group>


                            <group scale={3} position={[-0.9, -29.5, 0]}>
                                <MemoizedBlackHole textures={memoizedTextures} setIsActive={props.setIsActive} />
                            </group>
                        </Scroll>
                    </ScrollControls>
                </>)
            }

        </>
    );
}

