import React, { useMemo, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { animated, useSpring } from '@react-spring/three';
import OrbitGroup from './Objects/Extras/OrbitGroup';
import { ClampToEdgeWrapping, DoubleSide, EquirectangularReflectionMapping, PlaneGeometry, RepeatWrapping } from 'three';

const Background = React.memo((props) => {
    // References to the meshes
    const mesh1Ref = useRef();
    const mesh2Ref = useRef();
    const mesh3Ref = useRef();

    // Memoized geometry and material properties
    const planeGeometry = useMemo(() => new PlaneGeometry(1.6, 0.8), []);
    const materialProps = useMemo(() => ({
        transparent: true,
        opacity: 1
    }), []);

    useFrame((state, delta) => {
        if (mesh1Ref.current) {
            mesh1Ref.current.rotation.z += delta * 0.005; // Adjust rotation speed as needed
        }
        if (mesh2Ref.current) {
            mesh2Ref.current.rotation.z -= delta * 0.008; // Opposite direction
        }
        if (mesh3Ref.current) {
            mesh3Ref.current.rotation.z += delta * 0.01; // Adjust rotation speed as needed
        }
    });

    const textureRepeated = props.textures.background;
    textureRepeated.wrapS = RepeatWrapping;
    textureRepeated.wrapT = RepeatWrapping;
    textureRepeated.repeat.set(5, 5);


    const textureRepeated2 = props.textures.background2;
    textureRepeated2.wrapS = RepeatWrapping;
    textureRepeated2.wrapT = RepeatWrapping;
    textureRepeated2.repeat.set(10, 10);


    const textureRepeated3 = props.textures.background3;
    textureRepeated3.wrapS = RepeatWrapping;
    textureRepeated3.wrapT = RepeatWrapping;
    textureRepeated3.repeat.set(8, 8);

    return (
        <>
        
            {/* logo */}
            <mesh
                ref={mesh1Ref}
                // geometry={planeGeometry}
                position={[-8.5, 3.8, -20]}
                scale={[1.7,0.8,1.7]}
            >
                <planeGeometry />
                <meshBasicMaterial
                    side={DoubleSide}
                    opacity={1}
                    depthWrite={false}
                    transparent
                    map={props.textures.logo}
                />
            </mesh>

            {/* center */}
            <mesh
                ref={mesh1Ref}
                // geometry={planeGeometry}
                position={[0, 0, -15]}
                scale={60}
            >
                <sphereGeometry />
                <meshBasicMaterial
                    side={DoubleSide}
                    {...materialProps}
                    opacity={1}
                    map={textureRepeated}
                />
            </mesh>

            <mesh
                ref={mesh2Ref}
                // geometry={planeGeometry}
                position={[0, 0, -2]}
                scale={60}
            >
                <sphereGeometry />
                <meshBasicMaterial
                    side={DoubleSide}
                    {...materialProps}
                    opacity={1}
                    map={textureRepeated2}
                />
            </mesh>

            <mesh
                ref={mesh3Ref}
                // geometry={planeGeometry}
                position={[0, 0, 0.5]}
                scale={60}
            >
                <sphereGeometry />
                <meshBasicMaterial
                    side={DoubleSide}
                    {...materialProps}
                    opacity={1}
                    map={textureRepeated3}
                />
            </mesh>

            {props.windowWidth > 1060 ? (
                <OrbitGroup axisOpacity={props.axisOpacity} />
            ) : null}
        </>
    );
});

export default Background;
