import React, { useRef, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { MeshStandardMaterial, RepeatWrapping } from 'three';

export function OBJDAO(props) {
  const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/dao/latestDao.glb`);

  // Refs for rotation and opacity
  const rotationYRef = useRef(1);
  const opacityRef = useRef(1.0);

  // Refs to hold the mesh elements
  const groupRef = useRef();
  const animatedMeshRef = useRef();

  // Memoized material definitions to avoid re-creating them on every render
  const ledMaterials = useMemo(() => new MeshStandardMaterial({
    emissive: 'cyan',
    emissiveIntensity: 3,
    color: 'cyan',
    transparent: true,
    wireframe: true
  }), []);

  const bumpMap = useMemo(() => {
    const map = materials.Planet.map;
    map.wrapS = map.wrapT = RepeatWrapping;
    map.repeat.set(1, 1);
    return map;
  }, [materials.Planet.map]);

  const materialPlanet = useMemo(() => new MeshStandardMaterial({
    map: bumpMap,
    bumpMap: bumpMap,
    metalness: 0.3,
    roughness: 0.8,
    bumpScale: 10,
    transparent: true
  }), [bumpMap]);

  useFrame((state, delta) => {
    // Update rotation
    rotationYRef.current += delta * (2 * Math.PI / 50); // Rotate over 50 seconds
    if (rotationYRef.current > 2 * Math.PI + 1) {
      rotationYRef.current -= 2 * Math.PI;
    }
    if (groupRef.current) {
      groupRef.current.rotation.y = rotationYRef.current;
    }

    // Update opacity
    const time = state.clock.getElapsedTime(); // Total elapsed time
    const fadeDuration = 3; // Duration for one complete fade cycle
    opacityRef.current = 0.1 * (Math.sin((time / fadeDuration) * 2 * Math.PI) + 1); // Oscillate opacity between 0 and 1

    // Update opacity for animatedMeshRef
    if (animatedMeshRef.current) {
      animatedMeshRef.current.material.opacity = opacityRef.current;
    }
  });

  // Render meshes
  return (
    <group {...props} ref={groupRef} dispose={null}>
      <mesh
        geometry={nodes.Sphere.geometry}
        rotation={[Math.PI / 2, 0, 0]}
        material={materialPlanet}
      />
      <mesh
        ref={animatedMeshRef}
        geometry={nodes.X.geometry}
        material={materials.xGlow}
        rotation={[Math.PI / 2, 0, 0]}
        position={[-3, 0, 0]}
      >
        <meshStandardMaterial
          wireframe
          emissive={"cyan"}
          emissiveIntensity={3}
          color={"cyan"}
          transparent
        />
      </mesh>
      {['circularGlow1', 'circularGlow2', 'circularGlow3', 'circularGlow4', 'circularGlow5', 'circularGlow5_1', 'rectangularGlow1', 'rectangularGlow2', 'rectangularGlow3', 'rectangularGlow4', 'rectangularGlow5'].map((name, idx) => (
        <mesh
          key={name}
          geometry={nodes[name]?.geometry}
          material={ledMaterials}
          rotation={[Math.PI / 2, 0, 0]}
        />
      ))}
      <mesh
        geometry={nodes.Color.geometry}
        material={materials.rectangularColor}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial
          color={"grey"}
          metalness={1}
          roughness={1}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/dao/latestDao.glb`);
