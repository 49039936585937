import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshStandardMaterial, RepeatWrapping } from 'three';
import { useSpring, animated } from '@react-spring/three';

export default function X(props) {
    const { nodes, materials } = useGLTF(`${process.env.PUBLIC_URL}/v2/xid/x3.glb`);

    const bumpMap = materials.Mat.map;
    bumpMap.wrapS = bumpMap.wrapT = RepeatWrapping;
    bumpMap.repeat.set(1, 1);

    const material = new MeshStandardMaterial({
        map: materials.Mat.map,
        bumpMap: bumpMap,
        bumpScale: 50,
        transparent: true
    });

    const { emissiveIntensity } = useSpring({
        from: { emissiveIntensity: 1 },
        to: { emissiveIntensity: 2.2 },
        config: { duration: 2000 },
        loop: { reverse: true }
    });

    return (
        <group {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, Math.PI]} scale={1}>
                <mesh   geometry={nodes.Dao.geometry} material={material} />
                <animated.mesh   geometry={nodes.x.geometry} scale={1}>
                    <animated.meshStandardMaterial 
                        wireframe 
                        emissive={"cyan"} 
                        emissiveIntensity={emissiveIntensity} 
                        color={"cyan"} 
                        transparent 
                    />
                </animated.mesh>
            </group>
        </group>
    )
}

useGLTF.preload(`${process.env.PUBLIC_URL}/v2/xid/x3.glb`);
