import logo from '../logo.svg';
import '../App.css';
import { useLoader, useThree } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

export default function Sound(props) {
    const { camera } = useThree();
    const [listener] = useState(() => new THREE.AudioListener());
    const [buffer, setBuffer] = useState(null);
    const [hasInteracted, setHasInteracted] = useState(false); // Track user interaction

    useEffect(() => {
        const loader = new THREE.AudioLoader();
        loader.load(
            props.link,
            (loadedBuffer) => {
                setBuffer(loadedBuffer);
            },
            undefined,
            (error) => {
                console.error('Error loading audio file:', error);
            }
        );
    }, [props.link]);

    useEffect(() => {
        if (buffer && props.sound.current && hasInteracted) {
            // console.log('Setting up audio...');
            props.sound.current.setBuffer(buffer);
            props.sound.current.setLoop(true);
            props.sound.current.setVolume(2.5);
            props.sound.current.setDirectionalCone(30, 90, 0.1);
            props.sound.current.play();
        }
    }, [buffer, hasInteracted]);

    useEffect(() => {
        camera.add(listener);
        return () => {
            camera.remove(listener);
        };
    }, [camera, listener]);

    const handleUserInteraction = () => {
        const video = document.getElementById('advert');
        if (video) {
            video.muted = true;
            video.volume = 0.05;
            video.play();
        }
        if (!hasInteracted) {
            setHasInteracted(true);
        }
    };

    useEffect(() => {
        window.addEventListener('touch', handleUserInteraction);
        return () => {
            window.removeEventListener('touch', handleUserInteraction);
        };
    }, [hasInteracted]);

    useEffect(() => {
        window.addEventListener('click', handleUserInteraction);
        return () => {
            window.removeEventListener('click', handleUserInteraction);
        };
    }, [hasInteracted]);

    useEffect(() => {
        window.addEventListener('scroll', handleUserInteraction);
        return () => {
            window.removeEventListener('scroll', handleUserInteraction);
        };
    }, [hasInteracted]);

    return <positionalAudio ref={props.sound} args={[listener]} />;
}
