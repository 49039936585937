// fragmentShader.js

const fragmentShader = `varying float vDistance;

void main() {
  vec3 color = vec3(15.0 / 255.0, 255.0 / 255.0, 255.0 / 255.0);
  float strength = distance(gl_PointCoord, vec2(0.5));
  strength = 1.0 - strength;
  strength = pow(strength, 3.0);

  color = mix(color, vec3(15.0 / 255.0, 255.0 / 255.0, 255.0 / 255.0), vDistance * 0.5);
  color = mix(vec3(0.0), color, strength);
  gl_FragColor = vec4(color, strength);
}

`;

export default fragmentShader;
