import { CylinderGeometry, DoubleSide, Euler, MeshBasicMaterial, Vector3 } from 'three';
import React, { useMemo } from 'react';

export default function OrbitGroup({ axisOpacity }) {
    const positions = useMemo(() => [
        new Vector3(-0.1, 0, 0),
        new Vector3(-0.1, 0, 0),
        new Vector3(-0.1, 0, 0),
        new Vector3(-0.1, 0, 0),
        new Vector3(-0.1, 0, 0)
    ], []);

    const scales = useMemo(() => [
        new Vector3(3, 3, 3),
        new Vector3(4, 4, 4),
        new Vector3(6, 6, 6),
        new Vector3(8, 8, 8),
        new Vector3(8.5, 8.5, 8.5)
    ], []);

    const rotations = useMemo(() => [
        new Euler(0.4, 0, 0),
        new Euler(0.4, 0, 0),
        new Euler(0.4, 0, 0.4),
        new Euler(0.5, 0, 0),
        new Euler(0.5, 0, 0.3)
    ], []);

    const cylinderArgs = useMemo(() => [0.6, 0.6, 0.002, 80, 1, true], []); // Reduced segments for minimum vertices

    return (
        <>
            {positions.map((position, i) => (
                <mesh
                    userData={{ lensflare: 'no-occlusion' }}
                    key={i}
                    position={position}
                    scale={scales[i]}
                    rotation={rotations[i]}
                >
                    <cylinderGeometry args={cylinderArgs} />
                    <meshBasicMaterial
                        color={"#ffffff"}
                        side={DoubleSide}
                        opacity={axisOpacity}
                        depthWrite={false}
                        transparent={true}
                    />
                </mesh>
            ))}
        </>
    );
}
