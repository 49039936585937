import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere, Torus, TorusKnot } from '@react-three/drei';
import Lights from '../../Lights';
import { Color, DoubleSide, Matrix4, MeshBasicMaterial, MeshStandardMaterial, RawShaderMaterial, RepeatWrapping, ShaderMaterial, SphereGeometry, TextureLoader, Vector2, Vector3 } from 'three';
import LinesDAO from './LinesGold';
import CloudsDAO from './SparklesGold';
import SparklesGold from './SparklesGold';
import LinesGold from './LinesGold';
import Spaceship1 from './Spaceship1';
import Spaceship2 from './Spaceship2';
import Train from '../Extras/Train/Train';
import Road from '../Extras/Train/Road';
import Ring from './RingGold';
import SingleSparkle from './SingleSparkle';
import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { useSpring, animated } from '@react-spring/three';
import { Asteroid1 } from './Asteroid1';
import { Asteroid2 } from './Asteroid2';
import { useControls } from 'leva';
import { ImageUtils } from 'three';

export default function Gold(props) {

    const material = new MeshStandardMaterial({ map: props.textures.textureGold });

    const moveCamera = () => {
        document.title = "ProjectX • Gold";
        props.setInFocus("gold");
        //props.setAxisOpacity(0);
        if (props.windowWidth > 1060)
            props.controlsRef.current?.setLookAt(-3.7, -1.8, 8, -3.7, -1.8, 1, true);
    }


    const { rotationY: rotationYGold } = useSpring({
        from: { rotationY: 0 },
        to: { rotationY: 2 * Math.PI },
        config: { duration: 90000, easing: t => t },
        loop: true,
    });


    return (
        <>
            <group
                frustumCulled
                onPointerEnter={() => { props.setIsActive("Gold") }}
                onPointerLeave={() => { props.setIsActive(null) }}
                onClick={() => {
                    moveCamera()
                }}>

                <Asteroid2 animationGold={props.animationGold} windowWidth={props.windowWidth} setAnimationGold={props.setAnimationGold} scale={props.scale} position={[props.position[0], props.position[1], props.position[2]]} />

                <animated.mesh
                    rotation-y={rotationYGold}
                    position={props.position} geometry={props.sphere}
                    material={material}
                    scale={props.scale} />
            </group>
        </>
    );
}

