const fragmentShader = `
uniform sampler2D u_texture; // video texture uniform

varying vec2 vUv;

void main() {
 
  vec2 rotatedUV = vec2(vUv.y, 1.0 - vUv.x); // position adjustment directly within the shader
  
  vec4 textureColor = texture2D(u_texture, rotatedUV); // sample the video texture using the scaled and centered UV coordinates

  gl_FragColor = vec4(textureColor.rgb, 0.8); // adv opacity changez
}
`;

export default fragmentShader;
