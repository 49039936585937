import logo from './logo.svg';
import './App.css';
import { Canvas } from '@react-three/fiber';
import { Html, Loader, OrbitControls, PerspectiveCamera, PresentationControls, useProgress } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { MeshStandardMaterial, SphereGeometry } from 'three';
import Background from './Background';
import { Suspense, useState } from 'react';
import contur from './border2.webp';
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faArrowUpRightFromSquare, faSign, faXmark } from '@fortawesome/free-solid-svg-icons';
import SoundLine from './SoundLine'
import Marquee from 'react-fast-marquee'

export default function Hud(props) {

    const goToDapp = () => {
        window.open('https://dapp.projectx.mx/', '_blank');
    }

    const goToTwitter = () => {
        window.open('https://twitter.com/ProjectX_DAO', '_blank');
    }

    const goToDiscord = () => {
        window.open('https://discord.projectx.mx', '_blank');
    }

    const goToMedium = () => {
        window.open('https://medium.com/@ProjectX_dao', '_blank');
    }

    const goToGitbook = () => {
        window.open('https://docs.projectx.mx/', '_blank');
    }

    const goToXoxno = () => {
        window.open('https://xoxno.com/collection/SUBJECTX-2c184d', '_blank');
    }

    const goToFlipX = () => {
        window.open('https://flipix.io/collection/SUBJECTX-2c184d', '_blank');
    }

    const close = () => {
        props.setAxisOpacity(0.1);
        props.controlsRef.current?.setLookAt(0, 0, 30, 0, 0, 1, true);
        props.setInFocus(null);
        document.title = "ProjectX • Home";
    }

    const openLink = (link) => {
        window.open(link, '_blank');
    }

    const [earthPage, setEarthPage] = useState(1);
    const [teamMember, setTeamMember] = useState(null);

    const teamMembers = [
        {
            name: 'Dr. X',
            twitter: '@AnduTabacu',
            position: 'Creator',
            imageUrl: './v2/Team/doctorX.webp',
            link: 'https://x.com/AnduTabacu'
        },
        {
            name: 'xOxSaurOn',
            twitter: '@tuslaw_x0x',
            position: 'Creator',
            imageUrl: './v2/Team/x0xSaur0n.webp',
            link: 'https://x.com/tuslaw_x0x'
        },
        {
            name: 'CyberSpartanX',
            twitter: '@CyberSpartanX',
            position: 'Creator',
            imageUrl: './v2/Team/CyberSpartanX.webp',
            link: 'https://x.com/CyberSpartanX'
        },
        {
            name: 'Cyborg',
            twitter: '@alex-gavrila',
            position: 'Developer',
            imageUrl: './v2/Team/Cyborg.webp',
            link: 'https://www.linkedin.com/in/alex-gavrila-31183a162'
        },
        {
            name: 'Bubu',
            twitter: '@BubuMVX',
            position: 'Developer',
            imageUrl: './v2/Team/Bubu.webp',
            link: 'https://x.com/BubuMVX'
        },
        {
            name: 'Offheadharry',
            twitter: '@harryjburrell',
            position: 'DC Leader',
            imageUrl: './v2/Team/Offheadharry.webp',
            link: 'https://www.linkedin.com/in/harryjburrell/'
        },
        {
            name: 'Yannic',
            twitter: '@yannic',
            position: 'Advisor',
            imageUrl: './v2/Team/Yannic.webp',
            link: 'https://www.linkedin.com/in/yannic-b%C3%BClow-607a17127/'
        },
        {
            name: 'Chessucation',
            twitter: '@chessucation',
            position: '-',
            imageUrl: './v2/Team/Chessucation.webp',
            link: 'https://www.linkedin.com/in/lukas-seel-334b73159/'
        },
        {
            name: 'Moisic',
            twitter: '@alexandra-moisescu',
            position: 'Web2 Marketing Manager',
            imageUrl: './v2/Team/Moisic.webp',
            link: 'https://www.linkedin.com/in/alexandra-moisescu-0238a3202/'
        },
        {
            name: 'CDS',
            twitter: '@CDS61617',
            position: 'Moderator',
            imageUrl: './v2/Team/CDS.webp',
            link: 'https://x.com/CDS61617'
        },
        {
            name: 'LuckyBirdsLabs',
            twitter: '@LuckyBirdsLabs ',
            position: 'Dev Team',
            imageUrl: './v2/Team/LuckyBirdsLabs.webp',
            link: 'https://x.com/LuckyBirdsLabs'
        },
    ];

    return (
        <>
            {
                props.windowWidth > 1060 ? (<>
                    <SoundLine sound={props.sound} />
                    <div className='projx-btn' style={{ position: 'fixed', top: '2rem', right: '2rem', zIndex: 1, paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', borderRadius: 20, WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)' }} onClick={() => { goToDapp() }}>dApp</div>

                    <div style={{ position: 'fixed', width: '20rem', bottom: '2rem', left: '2rem', zIndex: 1, padding: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', borderRadius: 20, WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)' }}>
                        <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./twitter.svg' onClick={() => { goToTwitter() }} />
                        <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./discord.svg' onClick={() => { goToDiscord() }} />
                        <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./medium.svg' onClick={() => { goToMedium() }} />
                        <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./gitbook.svg' onClick={() => { goToGitbook() }} />
                        <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./xoxno.png' onClick={() => { goToXoxno() }} />
                        <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./flipix.svg' onClick={() => { goToFlipX() }} />
                    </div>

                    {/* EVENTS HUD START*/}
                    {/* <div style={{ position: 'fixed', bottom: '5rem', right: '2rem', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>Simulate events</div>

                    <div style={{ position: 'fixed', bottom: '2rem', right: '2rem', zIndex: 1, paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '0.5rem', paddingRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem', borderRadius: 20 }}>
                        <div className='projx-btn' style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }} onClick={() => { props.setAnimationSun(!props.animationSun) }}>Sun</div>
                        <div className='projx-btn' style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }} onClick={() => { props.setAnimationUnknown(!props.animationUnknown) }}>Unknown</div>
                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationSaturn(!props.animationSaturn) }}
                        >
                            Saturn
                        </div>
                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationGlitch(!props.animationGlitch) }}>
                            Glitch
                        </div>

                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationRing(!props.animationRing) }}>
                            Ring
                        </div>

                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationEdge(!props.animationEdge) }}>
                            EDGE
                        </div>

                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter("1") }}>
                            1
                        </div>


                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter("2") }}>
                            2
                        </div>


                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter("3") }}>
                            3
                        </div>


                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter("4") }}>
                            4
                        </div>


                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter("5") }}>
                            5
                        </div>

                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter("6") }}>
                            6
                        </div>

                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationGold(9) }}>
                            Gold 9 diamonds
                        </div>

                        <div
                            className='projx-btn'
                            style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: 20 }}
                            onClick={() => { props.setAnimationCanvasFilter(null) }}>
                            Revert
                        </div>

                    </div> */}
                    {/* EVENTS HUD END*/}


                    <div className={props.inFocus === "gold" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20 }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Gold Planet</div>
                        <div style={{ fontSize: 40, height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                            <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/products/the-gold-planet'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                            <img className='icon' onClick={() => { openLink('https://discord.projectx.mx'); }} src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-round-black-icon.png' style={{ color: 'white', width: '3rem', fill: 'white', filter: 'invert(100%)' }} />
                            <img className='icon' onClick={() => { openLink('https://t.me/+-WcwqSVXFEcwNzA8'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4.5rem', fill: 'white', filter: 'invert(100%)' }} />
                        </div>

                        <div style={{ width: '30rem', borderRadius: 40, borderLeft: 'solid 2px white', borderRight: 'solid 2px white', position: 'relative', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem' }}>
                            <Marquee gradientColor="#000000" autoFill speed={50} gradient style={{ borderRadius: 40, position: 'relative', gap: '0rem', height: '4rem' }}>
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/1.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/2.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/3.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/4.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/5.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/6.webp' />

                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/1.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/2.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/3.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/4.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/5.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/6.webp' />

                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/1.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/2.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/3.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/4.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/5.webp' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/6.webp' />
                            </Marquee>
                        </div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            A Fraud-Safe Protocol that secures the DAO, subDAOs, and project funds using Smart Contracts & multi-sign wallets. All interactions run on a 2 of 3 model based on a vote system.
                        </div>
                    </div>

                    <div className={props.inFocus === "unknown" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20 }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>The Unknown</div>
                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            An undisclosed phase that catapults Project X deeper into the cross-chain space.
                            <br />The mystery continues...
                        </div>
                    </div>

                    <div className={props.inFocus === "saturn" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20 }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>SATURN</div>
                        <div style={{ fontSize: 40, height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                            <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/products/saturn'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                            <img className='icon' onClick={() => { openLink('https://discord.projectx.mx'); }} src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-round-black-icon.png' style={{ color: 'white', width: '3rem', fill: 'white', filter: 'invert(100%)' }} />
                            <img className='icon' onClick={() => { openLink('https://t.me/+-WcwqSVXFEcwNzA8'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4.5rem', fill: 'white', filter: 'invert(100%)' }} />
                        </div>

                        <div style={{ width: '30rem', borderRadius: 40, borderLeft: 'solid 2px white', borderRight: 'solid 2px white', position: 'relative', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem' }}>
                            <Marquee gradientColor="#000000" autoFill speed={50} gradient style={{ borderRadius: 40, position: 'relative', gap: '0rem', height: '4rem' }}>
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/1.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/2.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/3.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/4.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/5.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/6.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/7.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/8.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/9.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/10.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/11.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/12.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/13.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/14.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/15.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/16.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/17.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/18.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/19.webp' />
                                <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/20.webp' />
                            </Marquee>
                        </div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center' }}>
                            Saturn is an all-in-one comprehensive solution to help communities handle tasks with ease and achieve high levels of organization and productivity. Trusted by over 15.000 users and 100 communities.
                        </div>
                    </div>

                    <div className={props.inFocus === "edge" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20 }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>EDGE</div>
                        <div style={{ fontSize: 40, height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                            <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/products/edge'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '0rem' }} />
                            <img className='icon' onClick={() => { openLink('https://t.me/Edge_ProjectX_bot?start=354f98c1'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4rem', fill: 'white', filter: 'invert(100%)', marginRight: '0rem' }} />
                        </div>
                        <div style={{ fontSize: 22, textAlign: 'center' }}>
                            The fastest Telegram bot on MultiversX.
                        </div>

                        <div style={{ width: '30rem', borderRadius: 40, borderLeft: 'solid 2px white', borderRight: 'solid 2px white', position: 'relative', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem' }}>
                            <Marquee gradientColor="#000000" autoFill speed={50} gradient style={{ borderRadius: 40, position: 'relative', gap: '0rem', height: '4rem' }}>
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/1.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/2.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/3.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/4.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/5.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/6.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/7.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/8.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/9.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/10.svg' />

                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/11.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/12.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/13.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/14.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/15.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/16.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/17.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/18.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/19.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/20.svg' />

                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/21.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/22.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/23.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/24.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/25.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/26.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/27.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/28.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/29.svg' />
                                <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/30.svg' />
                            </Marquee>
                        </div>


                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            EDGE is an all-in-one Telegram trading bot that makes trading any token (ESDT) on MultiversX blockchain fun for everyone.<br />
                            Lightning-fast, user-friendly, and designed for seamless onboarding of new users.<br />
                            With its clean design and top-notch security, EDGE is the most advanced bot offering features suitable for both beginners and experts.
                        </div>
                    </div>

                    <div className={props.inFocus === "ring" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>The Ring</div>
                        <div style={{ fontSize: 40, height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                            <img className='icon' onClick={() => { openLink('https://dapp.projectx.mx/enhancement'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                        </div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            A portal to a new world filled with experiences, storytelling, and utility.

                            Upgrade your Subject X to get better rewards and all the advantages of a DAO member.
                        </div>

                        <div style={{ position: 'relative', width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', flexDirection: 'row', gap: '1rem' }}>
                            <div onClick={() => {
                                window.open("https://dapp.projectx.mx/enhancement")
                            }}
                                className='btn-link'
                                style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                Go to The Enhancement.
                            </div>
                        </div>
                    </div>

                    <div className={props.inFocus === "dao" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>The X-DAO</div>
                        <div style={{ fontSize: 40, height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                            <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/project-x/dao'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                            <img className='icon' onClick={() => { openLink('https://t.me/+-WcwqSVXFEcwNzA8'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4.5rem', fill: 'white', filter: 'invert(100%)' }} />
                        </div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            The DAO lies at the heart of Project X. It is a transparent and democratic governance structure that allows holders (Subject X) and employees to manage and control the project.
                        </div>

                        <div style={{ position: 'relative', width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', flexDirection: 'row', gap: '1rem' }}>
                            <div onClick={() => {
                                window.open("https://peerme.io/project-x")
                            }}
                                className='btn-link'
                                style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                Go to PeerMe.
                            </div>
                        </div>
                    </div>

                    <div className={props.inFocus === "faction-1" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Naxaris</div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            Under construction...
                        </div>

                    </div>

                    <div className={props.inFocus === "faction-2" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Xyrios</div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            Under construction...
                        </div>

                    </div>

                    <div className={props.inFocus === "faction-3" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Mexus</div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            Under construction...
                        </div>

                    </div>

                    <div className={props.inFocus === "faction-4" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Kyronix</div>
                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            Under construction...
                        </div>
                    </div>

                    <div className={props.inFocus === "faction-5" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Gornax</div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            Under construction...
                        </div>
                    </div>

                    <div className={props.inFocus === "faction-6" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>Divergent Club</div>

                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            A vibrant subDAO within Project X, featuring a unique collection of 2,888 generative, pixelized characters that journey across the MultiversX and Bitcoin Ordinal blockchains. Drawing inspiration from anime and pop culture, these characters offer playful parodies of well-known references, each rendered in a distinct, pixelated style. Divergent Club combines nostalgia with cutting-edge blockchain technology, creating a dynamic community that celebrates creativity, individuality, and the fusion of classic and modern influences.
                        </div>

                        <div style={{ position: 'relative', width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', flexDirection: 'row', gap: '1rem', top: '1rem' }}>
                            <div onClick={() => {
                                window.open("https://www.divergentclub.xyz/")
                            }}
                                className='btn-link'
                                style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                Go to DC
                            </div>
                            <div onClick={() => {
                                window.open("https://rug.divergentclub.xyz/login")
                            }}
                                className='btn-link'
                                style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                Go to Rug Royalties
                            </div>
                        </div>
                    </div>

                    <div className={props.inFocus === "spaceport" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>The Spaceport</div>
                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            Prediction markets on MultiversX.
                        </div>
                    </div>

                    <div className={props.inFocus === "xid" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', left: "2rem", zIndex: 22, borderRadius: 20, }}>
                        <div style={{ position: 'absolute', top: '2rem', right: '2rem', fontSize: 25 }} onClick={() => { close() }}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div style={{ fontSize: 40, height: '4rem' }}>xID</div>
                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', width: '80%' }}>
                            The profile app. <br />
                            Coming soon...
                        </div>
                    </div>

                    <div className={props.inFocus === "earth" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '70%', top: '10%', width: '50%', right: "2rem", zIndex: 22, backgroundColor: 'rgba(0,0,0,0.8)', borderRadius: 20, }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '98%', height: '98%', position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '-1rem', right: '-1rem', fontSize: 25 }} onClick={() => { close() }}><FontAwesomeIcon icon={faXmark} /></div>
                            <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '2rem' }}>
                                {
                                    earthPage === 1 ? (
                                        <>
                                            <div style={{ fontSize: 40, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>Project X: A New World Unveiled</div>
                                            <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', height: '100%', paddingRight: '1rem', paddingLeft: '1rem' }}>
                                                In the vast expanse of digital innovation, Project X emerges as a beacon of creativity and opportunity. <br /> <br /> It is an umbrella brand encompassing a variety of standalone products, each unique yet interconnected. Together, they form an ecosystem teeming with endless possibilities and excitement, where boundaries are pushed, and new frontiers are explored.<br /> <br />
                                                ProjectX is where innovation meets imagination, inviting all who dare to dream to be a part of something greater.
                                            </div>
                                        </>

                                    ) :
                                        earthPage === 2 ? (
                                            <>
                                                <div style={{ fontSize: 40, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>The Pulse of Project X: Our Community</div>
                                                <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', height: '65%', paddingRight: '1rem', paddingLeft: '1rem' }}>
                                                    At Project X, the community is our lifeblood. It’s where the Subjects (NFTs) live, breathe, and interact daily. <br /> <br /> Our community gathers for meetups, participates in global events, and engages in enriching discussions. It’s also where members showcase their creativity through contests and campaigns. <br /> <br />
                                                    Beyond the digital world, Project X extends its presence through exclusive merchandise, allowing members to take a piece of the project into their everyday lives.<br /> <br />
                                                    Join us, be part of our meetups, celebrate with us, and represent the spirit of Project X with pride.
                                                </div>
                                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15%', flexDirection: 'row', gap: '2rem' }}>
                                                    <div onClick={() => {
                                                        window.open("https://xoxno.com/collection/SUBJECTX-2c184d")
                                                    }}
                                                        className='btn-link'
                                                        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '60%', paddingTop: '1rem', paddingBottom: '1rem', textAlign: 'center' }}>
                                                        Buy Subject X.
                                                    </div>
                                                    <div onClick={() => {
                                                        window.open("https://xexchange.com/tokens/DNA-b144d1?firstToken=EGLD&secondToken=DNA-b144d1")
                                                    }}
                                                        className='btn-link'
                                                        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '60%', paddingTop: '1rem', paddingBottom: '1rem', textAlign: 'center' }}>
                                                        Buy $DNA.
                                                    </div>
                                                    <div onClick={() => {
                                                        window.open("https://xoxno.com/collection/DCLUB-b0ddcb")
                                                    }}
                                                        className='btn-link'
                                                        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '60%', paddingTop: '1rem', paddingBottom: '1rem', textAlign: 'center' }}>
                                                        Buy Divergent Club.
                                                    </div>
                                                </div>
                                            </>

                                        ) :
                                            earthPage === 3 ? (
                                                <>
                                                    <div style={{ fontSize: 40, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>Meet the Team</div>
                                                    <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', height: '100%', paddingRight: '1rem', paddingLeft: '1rem' }}>
                                                        The beating heart of Project X is its talented and dedicated team. Each member brings a unique perspective and set of skills, united by a common vision to drive innovation and foster a thriving ecosystem.<br /> <br /> From developers and designers to strategists and community managers, our team is committed to pushing the boundaries of what's possible.<br /> <br />
                                                        Meet our team members, the minds behind the magic, each bringing their own story and passion to Project X.

                                                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem', flexDirection: 'row', flexWrap: 'wrap', marginTop: '3rem', marginBottom: '3rem' }}>
                                                            {teamMembers.map((member, index) => (
                                                                <div
                                                                    onClick={() => { window.open(member.link, '_blank', 'noopener,noreferrer'); }}
                                                                    key={index}
                                                                    style={{
                                                                        position: 'relative',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <div
                                                                        onMouseEnter={() => setTeamMember(index)}
                                                                        onMouseLeave={() => setTeamMember(null)}
                                                                        style={{
                                                                            position: 'relative',
                                                                            width: '10rem',
                                                                            height: '10rem',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={teamMember === index ? 'blurOnHover active' : 'blurOnHover'}
                                                                            style={{
                                                                                position: 'relative',
                                                                                width: '10rem',
                                                                                height: '10rem',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                borderRadius: '50%',
                                                                                overflow: 'hidden',
                                                                                backgroundImage: `url(${member.imageUrl})`,
                                                                                backgroundSize: 'cover',
                                                                                backgroundPosition: 'center',
                                                                            }}
                                                                        >
                                                                        </div>
                                                                        <div className={teamMember === index ? 'showTeamMember show' : 'showTeamMember'} style={{ position: 'absolute', fontSize: 18, textAlign: 'center' }}>
                                                                            {member.position}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ color: 'white', fontSize: 20, marginTop: '1rem' }}>{member.name}</div>
                                                                    <div style={{ color: 'white', fontSize: 18 }}>{member.twitter}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>

                                            ) :
                                                earthPage === 4 ? (

                                                    <>
                                                        <div style={{ fontSize: 40, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>Join the DAO</div>
                                                        <div style={{ fontSize: 22, overflowY: 'scroll', textAlign: 'center', height: '100%', paddingRight: '1rem', paddingLeft: '1rem' }}>
                                                            At the heart of Project X lies a thriving community, brought together through social platforms and on-chain roles. These are the spaces where we share our latest news, host lively discussions, and engage with the vibrant community that drives Project X forward.<br /> <br />
                                                            To become a part of the journey you need a Subject X NFT.<br /> <br />
                                                            Get one today and help us shape the future of Project X and MultiversX!
                                                        </div>
                                                    </>
                                                ) : null
                                }

                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', gap: '2rem' }}>
                                    {earthPage > 1 ? (
                                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <div className='btn-link' onClick={() => { setEarthPage(earthPage - 1) }} style={{ position: 'relative', width: '50%', color: 'white', paddingTop: '0.5rem', paddingBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: 18, borderRadius: 40 }}>
                                                Back
                                            </div>
                                        </div>
                                    ) : null}

                                    {earthPage < 4 ? (
                                        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <div className='btn-link' onClick={() => { setEarthPage(earthPage + 1) }} style={{ position: 'relative', width: '50%', color: 'white', paddingTop: '0.5rem', paddingBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: 18, borderRadius: 40 }}>
                                                Next
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </>) :

                    (<>
                        {/* <SoundLine sound={props.sound} /> */}
                        <img style={{ position: 'fixed', top: '1rem', left: '0rem', zIndex: 1, width: '7rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', borderRadius: 20 }} src='./logo.webp' />

                        <div className='projx-btn' style={{ position: 'fixed', top: '2rem', right: '2rem', zIndex: 1, paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', borderRadius: 20, WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)' }} onClick={() => { goToDapp() }}>dApp</div>

                        <div style={{ position: 'fixed', width: '80%', left: '10%', bottom: '2rem', zIndex: 1, padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', borderRadius: 20, WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)' }}>
                            <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./twitter.svg' onClick={() => { goToTwitter() }} />
                            <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./discord.svg' onClick={() => { goToDiscord() }} />
                            <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./medium.svg' onClick={() => { goToMedium() }} />
                            <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./gitbook.svg' onClick={() => { goToGitbook() }} />
                            <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./xoxno.png' onClick={() => { goToXoxno() }} />
                            <img className='projx-btn-icon' onPointerEnter={() => { props.setIsActive(true) }} onPointerLeave={() => { props.setIsActive(null) }} style={{ position: 'relative', width: '2rem' }} src='./flipix.svg' onClick={() => { goToFlipX() }} />
                        </div>

                        <div className={props.inFocus === "gold" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 9 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>Gold Planet</div>
                            <div style={{ fontSize: 30, height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                                <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/products/the-gold-planet'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                                <img className='icon' onClick={() => { openLink('https://discord.projectx.mx'); }} src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-round-black-icon.png' style={{ color: 'white', width: '3rem', fill: 'white', filter: 'invert(100%)' }} />
                                <img className='icon' onClick={() => { openLink('https://t.me/+-WcwqSVXFEcwNzA8'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4.5rem', fill: 'white', filter: 'invert(100%)' }} />
                            </div>

                            <div style={{ width: '22rem', borderRadius: 40, borderLeft: 'solid 2px white', borderRight: 'solid 2px white', position: 'relative', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem' }}>
                                <Marquee gradientColor="#000000" gradientWidth={100} autoFill speed={50} gradient style={{ borderRadius: 40, position: 'relative', gap: '0rem', height: '4rem' }}>
                                    <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/1.webp' />
                                    <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/2.webp' />
                                    <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/3.webp' />
                                    <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/4.webp' />
                                    <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/5.webp' />
                                    <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./subdaos/6.webp' />
                                </Marquee>
                            </div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                A Fraud-Safe Protocol that secures the DAO, subDAOs, and project funds using Smart Contracts & multi-sign wallets. All interactions run on a 2 of 3 model based on a vote system.
                            </div>
                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "unknown" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>The Unknown</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                An undisclosed phase that catapults Project X deeper into the cross-chain space.
                                <br />The mystery continues...
                            </div>
                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "saturn" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', position: 'relative', padding: '2rem', gap: '2rem', borderRadius: 20 }}>

                                <div style={{ fontSize: 30, height: '3rem' }}>SATURN</div>
                                <div style={{ fontSize: 30, height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                                    <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/products/saturn'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                                    <img className='icon' onClick={() => { openLink('https://discord.projectx.mx'); }} src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-round-black-icon.png' style={{ color: 'white', width: '3rem', fill: 'white', filter: 'invert(100%)' }} />
                                    <img className='icon' onClick={() => { openLink('https://t.me/+-WcwqSVXFEcwNzA8'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4.5rem', fill: 'white', filter: 'invert(100%)' }} />
                                </div>


                                <div style={{ width: '22rem', borderRadius: 40, borderLeft: 'solid 2px white', borderRight: 'solid 2px white', position: 'relative', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem' }}>
                                    <Marquee gradientColor="#000000" gradientWidth={100} autoFill speed={50} gradient style={{ borderRadius: 40, position: 'relative', gap: '0rem', height: '4rem' }}>
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/1.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/2.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/3.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/4.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/5.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/6.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/7.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/8.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/9.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/10.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/11.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/12.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/13.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/14.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/15.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/16.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/17.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/18.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/19.webp' />
                                        <img style={{ position: 'relative', height: '3rem', borderRadius: 500, marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src='./projects/20.webp' />
                                    </Marquee>
                                </div>


                                <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                    Saturn is an all-in-one comprehensive solution to help communities handle tasks with ease and achieve high levels of organization and productivity. Trusted by over 15.000 users and 100 communities.
                                </div>
                                <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </div>
                            </div>

                        </div>

                        <div className={props.inFocus === "edge" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', position: 'relative', padding: '2rem', gap: '2rem', borderRadius: 20 }}>
                                <div style={{ fontSize: 30, height: '3rem' }}>EDGE</div>
                                <div style={{ fontSize: 30, height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                                    <img className='icon' onClick={() => { openLink('https://docs.projectx.mx/products/edge'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '0rem' }} />
                                    <img className='icon' onClick={() => { openLink('https://t.me/Edge_ProjectX_bot?start=354f98c1'); }} src='https://static.vecteezy.com/system/resources/previews/018/930/486/non_2x/telegram-logo-telegram-icon-transparent-free-png.png' style={{ color: 'white', width: '4rem', fill: 'white', filter: 'invert(100%)', marginRight: '0rem' }} />
                                </div>
                                <div style={{ fontSize: 22, textAlign: 'center' }}>
                                    The fastest Telegram bot on MultiversX.
                                </div>


                                <div style={{ width: '22rem', borderRadius: 40, borderLeft: 'solid 2px white', borderRight: 'solid 2px white', position: 'relative', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem' }}>
                                    <Marquee gradientColor="#000000" gradientWidth={100} autoFill speed={50} gradient style={{ borderRadius: 40, position: 'relative', gap: '0rem', height: '4rem' }}>
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/1.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/2.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/3.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/4.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/5.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/6.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/7.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/8.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/9.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/10.svg' />

                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/11.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/12.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/13.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/14.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/15.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/16.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/17.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/18.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/19.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/20.svg' />

                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/21.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/22.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/23.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/24.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/25.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/26.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/27.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/28.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/29.svg' />
                                        <img style={{ position: 'relative', height: '3rem', marginLeft: '0.5rem', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200 }} src='./tokens/30.svg' />

                                    </Marquee>
                                </div>


                                <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                    EDGE is an all-in-one Telegram trading bot that makes trading any token (ESDT) on MultiversX blockchain fun for everyone.<br />
                                    Lightning-fast, user-friendly, and designed for seamless onboarding of new users.<br />
                                    With its clean design and top-notch security, EDGE is the most advanced bot offering features suitable for both beginners and experts.
                                </div>

                                <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </div>
                            </div>
                        </div>

                        <div className={props.inFocus === "ring" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>The Ring</div>
                            <div style={{ fontSize: 30, height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
                                <img className='icon' onClick={() => { openLink('https://dapp.projectx.mx/enhancement'); }} src='https://cdn.icon-icons.com/icons2/3912/PNG/512/gitbook_logo_icon_248229.png' style={{ color: 'white', width: '3.5rem', fill: 'white', filter: 'invert(100%)', marginRight: '1rem' }} />
                            </div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                A portal to a new world filled with experiences, storytelling, and utility.
                                Upgrade your Subject X to get better rewards and all the advantages of a DAO member.
                            </div>

                            <div style={{ position: 'relative', width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', flexDirection: 'row', gap: '1rem' }}>
                                <div onClick={() => {
                                    window.open("https://dapp.projectx.mx/enhancement")
                                }}
                                    className='btn-link'
                                    style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                    Go to The Enhancement.
                                </div>
                            </div>
                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "dao" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>The X-DAO</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                The DAO lies at the heart of Project X. It is a transparent and democratic governance structure that allows holders (Subject X) and employees to manage and control the project.
                            </div>

                            <div style={{ position: 'relative', width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', flexDirection: 'row', gap: '1rem' }}>
                                <div onClick={() => {
                                    window.open("https://peerme.io/project-x")
                                }}
                                    className='btn-link'
                                    style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                    Go to PeerMe.
                                </div>
                            </div>

                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "faction-1" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>Naxaris</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                Under construction...
                            </div>

                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "faction-2" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>

                            <div style={{ fontSize: 30, height: '3rem' }}>Xyrios</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                Under construction...
                            </div>

                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>

                        </div>

                        <div className={props.inFocus === "faction-3" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>

                            <div style={{ fontSize: 30, height: '3rem' }}>Mexus</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                Under construction...
                            </div>

                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>

                        </div>

                        <div className={props.inFocus === "faction-4" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>

                            <div style={{ fontSize: 30, height: '3rem' }}>Kyronix</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                Under construction...
                            </div>

                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>

                        </div>

                        <div className={props.inFocus === "faction-5" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>

                            <div style={{ fontSize: 30, height: '3rem' }}>Gornax</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                Under construction...
                            </div>

                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>

                        </div>

                        <div className={props.inFocus === "faction-6" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>

                            <div style={{ fontSize: 30, height: '3rem' }}>Divergent Club</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                A vibrant subDAO within Project X, featuring a unique collection of 2,888 generative, pixelized characters that journey across the MultiversX and Bitcoin Ordinal blockchains. Drawing inspiration from anime and pop culture, these characters offer playful parodies of well-known references, each rendered in a distinct, pixelated style. Divergent Club combines nostalgia with cutting-edge blockchain technology, creating a dynamic community that celebrates creativity, individuality, and the fusion of classic and modern influences.
                            </div>

                            <div style={{ position: 'relative', width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', flexDirection: 'row', gap: '1rem', top: '1rem' }}>
                                <div onClick={() => {
                                    window.open("https://peerme.io/project-x")
                                }}
                                    className='btn-link'
                                    style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem', textAlign: 'center' }}>
                                    Go to DC.
                                </div>
                                <div onClick={() => {
                                    window.open("https://peerme.io/project-x")
                                }}
                                    className='btn-link'
                                    style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem', textAlign: 'center' }}>
                                    Go to Rug Royalities.
                                </div>
                            </div>
                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>

                        </div>

                        <div className={props.inFocus === "spaceport" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>The Spaceport</div>
                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                Prediction markets on MultiversX.
                            </div>
                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "xid" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22 }}>
                            <div style={{ fontSize: 30, height: '3rem' }}>xID</div>

                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', width: '80%', maxHeight: '10rem' }}>
                                The profile app.
                                <br />Coming soon..
                            </div>
                            <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>

                        <div className={props.inFocus === "earth" ? 'windowGold-phone show' : 'windowGold-phone'} style={{ position: 'fixed', height: '100%', bottom: '0%', width: '100%', zIndex: 22, backgroundColor: 'rgba(0,0,0,0.8)' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%', position: 'relative', paddingBottom: '1rem', gap: '2rem' }}>

                                <div style={{ position: 'relative', width: '90%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '2rem' }}>
                                    {
                                        earthPage === 1 ? (
                                            <>
                                                <div style={{ fontSize: 30, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', textAlign: 'center' }}>Project X: A New World Unveiled</div>
                                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div style={{ position: 'absolute', height: '3rem', bottom: 0, width: '100%', background: 'linear-gradient(to top, black, transparent)', pointerEvents:'none' }}></div>
                                                    <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', height: '100%', paddingRight: '1rem', paddingLeft: '1rem', maxHeight: '19rem', paddingBottom: '1rem' }}>
                                                        In the vast expanse of digital innovation, Project X emerges as a beacon of creativity and opportunity. <br /> <br /> It is an umbrella brand encompassing a variety of standalone products, each unique yet interconnected. Together, they form an ecosystem teeming with endless possibilities and excitement, where boundaries are pushed, and new frontiers are explored.<br /> <br />
                                                        ProjectX is where innovation meets imagination, inviting all who dare to dream to be a part of something greater.
                                                    </div>
                                                </div>
                                            </>

                                        ) :
                                            earthPage === 2 ? (
                                                <>

                                                    <div style={{ fontSize: 30, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', textAlign: 'center' }}>The Pulse of Project X: Our Community</div>

                                                    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div style={{ position: 'absolute', height: '3rem', bottom: 0, width: '100%', background: 'linear-gradient(to top, black, transparent)', pointerEvents:'none' }}></div>
                                                        <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', paddingRight: '1rem', paddingLeft: '1rem', maxHeight: '16rem', paddingBottom:'1rem' }}>
                                                            At Project X, the community is our lifeblood. It’s where the Subjects (NFTs) live, breathe, and interact daily. <br /> <br /> Our community gathers for meetups, participates in global events, and engages in enriching discussions. It’s also where members showcase their creativity through contests and campaigns.<br /> <br />
                                                            Beyond the digital world, Project X extends its presence through exclusive merchandise, allowing members to take a piece of the project into their everyday lives.<br /> <br />
                                                            Join us, be part of our meetups, celebrate with us, and represent the spirit of Project X with pride.
                                                        </div>
                                                    </div>

                                                    <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3.8rem', flexDirection: 'row', gap: '1rem' }}>
                                                        <div onClick={() => {
                                                            window.open("https://xoxno.com/collection/SUBJECTX-2c184d")
                                                        }}
                                                            className='btn-link'
                                                            style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem', padding: '0.5rem', height: '100%', textAlign: 'center' }}>
                                                            Buy Subject X.
                                                        </div>
                                                        <div onClick={() => {
                                                            window.open("https://xexchange.com/tokens/DNA-b144d1?firstToken=EGLD&secondToken=DNA-b144d1")
                                                        }}
                                                            className='btn-link'
                                                            style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18, borderRadius: 20, width: '60%', paddingTop: '1rem', paddingBottom: '1rem', padding: '0.5rem', height: '100%', textAlign: 'center' }}>
                                                            Buy $DNA.
                                                        </div>
                                                        <div onClick={() => {
                                                            window.open("https://xoxno.com/collection/DCLUB-b0ddcb")
                                                        }}
                                                            className='btn-link'
                                                            style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18, borderRadius: 20, width: '100%', paddingTop: '1rem', paddingBottom: '1rem', padding: '0.5rem', height: '100%', textAlign: 'center' }}>
                                                            Buy Divergent Club.
                                                        </div>

                                                    </div>
                                                </>

                                            ) :
                                                earthPage === 3 ? (
                                                    <>

                                                        <div style={{ fontSize: 30, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', textAlign: 'center' }}>Meet the Team</div>

                                                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div style={{ position: 'absolute', height: '3rem', bottom: 0, width: '100%', background: 'linear-gradient(to top, black, transparent)', pointerEvents:'none' }}></div>
                                                            <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', height: '100%', paddingRight: '1rem', paddingLeft: '1rem', maxHeight: '20rem' }}>
                                                                The beating heart of Project X is its talented and dedicated team. Each member brings a unique perspective and set of skills, united by a common vision to drive innovation and foster a thriving ecosystem.<br /> <br /> From developers and designers to strategists and community managers, our team is committed to pushing the boundaries of what's possible.<br /> <br />
                                                                Meet our team members, the minds behind the magic, each bringing their own story and passion to Project X.

                                                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem', flexDirection: 'row', flexWrap: 'wrap', marginTop: '3rem', marginBottom: '3rem' }}>
                                                                    {teamMembers.map((member, index) => (
                                                                        <div
                                                                            onClick={() => { window.open(member.link, '_blank', 'noopener,noreferrer'); }}
                                                                            key={index}
                                                                            style={{
                                                                                position: 'relative',
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                onMouseEnter={() => setTeamMember(index)}
                                                                                onMouseLeave={() => setTeamMember(null)}
                                                                                style={{
                                                                                    position: 'relative',
                                                                                    width: '10rem',
                                                                                    height: '10rem',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={teamMember === index ? 'blurOnHover active' : 'blurOnHover'}
                                                                                    style={{
                                                                                        position: 'relative',
                                                                                        width: '10rem',
                                                                                        height: '10rem',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        borderRadius: '50%',
                                                                                        overflow: 'hidden',
                                                                                        backgroundImage: `url(${member.imageUrl})`,
                                                                                        backgroundSize: 'cover',
                                                                                        backgroundPosition: 'center',
                                                                                    }}
                                                                                >
                                                                                </div>
                                                                                <div className={teamMember === index ? 'showTeamMember show' : 'showTeamMember'} style={{ position: 'absolute', fontSize: 18 }}>
                                                                                    {member.position}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ color: 'white', fontSize: 20, marginTop: '1rem' }}>{member.name}</div>
                                                                            <div style={{ color: 'white', fontSize: 18 }}>{member.twitter}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>

                                                ) :
                                                    earthPage === 4 ? (

                                                        <>

                                                            <div style={{ fontSize: 30, position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', textAlign: 'center' }}>Join the DAO</div>


                                                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div style={{ position: 'absolute', height: '3rem', bottom: 0, width: '100%', background: 'linear-gradient(to top, black, transparent)', pointerEvents:'none' }}></div>
                                                                <div style={{ fontSize: 20, overflowY: 'scroll', textAlign: 'center', height: '100%', paddingRight: '1rem', paddingLeft: '1rem', maxHeight: '20rem', paddingBottom:'2rem' }}>
                                                                    At the heart of Project X lies a thriving community, brought together through social platforms and on-chain roles. These are the spaces where we share our latest news, host lively discussions, and engage with the vibrant community that drives Project X forward.<br /> <br />
                                                                    To become a part of the journey you need a Subject X NFT.<br /> <br />
                                                                    Get one today and help us shape the future of Project X and MultiversX!
                                                                </div>
                                                            </div>
                                                        </>

                                                    ) : null
                                    }

                                    <div style={{ position: 'relative', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '1rem' }}>
                                        {earthPage > 1 ? (
                                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div className='btn-link' onClick={() => { setEarthPage(earthPage - 1) }} style={{ position: 'relative', width: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: 18, borderRadius: 40 }}>
                                                    Back
                                                </div>
                                            </div>
                                        ) : null}

                                        {earthPage < 4 ? (
                                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div className='btn-link' onClick={() => { setEarthPage(earthPage + 1) }} style={{ position: 'relative', width: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: 18, borderRadius: 40 }}>
                                                    Next
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className={'btn-link'} style={{ position: 'relative', fontSize: 22, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 200, height: '3rem', width: '3rem' }} onClick={() => { close() }}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>)
            }
        </>
    );
}