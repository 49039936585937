import React, { useRef } from 'react';
import { Plane } from '@react-three/drei';
import { DoubleSide, MeshBasicMaterial, RepeatWrapping } from 'three';
import { useSpring, animated } from '@react-spring/three';
import { useFrame } from '@react-three/fiber';


// PERFORMANCE ISSUE ON USE FRAME MIGHT BE - MUST CHECK LATER.
export default function Laser(props) {
    const texture = props.textures.laserDao;
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.repeat.set(1, 1);
    texture.offset.set(0, 0);

    const material = new MeshBasicMaterial({ map: texture, transparent: true });

    const { offsetY } = useSpring({
        from: { offsetY: 0 },
        to: { offsetY: 1 },
        config: { duration: 8000, easing: t => t },
        loop: true,
    });

    useFrame(() => {
        texture.offset.y = offsetY.get();
    });

    return (
        <Plane args={[3.2, 5]} position={props.position} material={material} rotation={props.rotation} scale={props.scale} />
    )
}
